@charset "UTF-8";
/* ================================================================= 
 License : e-TRUST Inc.
 File name : common_style.css
 Style : common_style
================================================================= */
/*黒*/
/*茶グレー*/
/*(赤茶)*/
/*()*/
/*(濃い茶)*/
/*(薄いピンク)*/
/*(黄色)*/
/*(エメラルド)*/
/*水色*/
/*
Zarigani Design Office Drawer Menu
Copyright 2018 Zarigani Design Office

Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
@import url(https://use.typekit.net/fhd7qnz.css);
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://fonts.googleapis.com/css2?family=Marcellus&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700|Noto+Serif+JP:300,400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
.zdo_drawer_menu * {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
  text-decoration: none;
  list-style: none;
}

.zdo_drawer_menu a {
  color: inherit;
  text-decoration: none;
}

.zdo_drawer_menu a:visited {
  color: inherit;
}

.zdo_drawer_menu .zdo_drawer_bg {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: rgba(51, 51, 51, 0.5);
  display: none;
  top: 0;
  left: 0;
}

.zdo_drawer_menu .zdo_drawer_button {
  display: block;
  background: none;
  border: none;
  padding: 0;
  width: 42px;
  letter-spacing: 0.1em;
  cursor: pointer;
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 1001;
  text-align: center;
  outline: none;
}

.zdo_drawer_menu .zdo_drawer_button.active .zdo_drawer_bar {
  width: 49px;
}

.zdo_drawer_menu .zdo_drawer_button.active .zdo_drawer_bar1 {
  transform: rotate(30deg);
}

.zdo_drawer_menu .zdo_drawer_button.active .zdo_drawer_bar2 {
  opacity: 0;
}

.zdo_drawer_menu .zdo_drawer_button.active .zdo_drawer_bar3 {
  transform: rotate(-30deg);
}

.zdo_drawer_menu .zdo_drawer_button.active .zdo_drawer_menu_text {
  display: none;
}

.zdo_drawer_menu .zdo_drawer_button.active .zdo_drawer_close {
  display: block;
}

.zdo_drawer_menu .zdo_drawer_bar {
  display: block;
  height: 2px;
  margin: 10px 0;
  transition: all 0.2s;
  transform-origin: 0 0;
}

.zdo_drawer_menu .zdo_drawer_text {
  text-align: center;
  font-size: 10px;
}

.zdo_drawer_menu .zdo_drawer_close {
  letter-spacing: 0.08em;
  display: none;
}

.zdo_drawer_menu .zdo_drawer_menu_text {
  display: block;
}

.zdo_drawer_menu .zdo_drawer_nav_wrapper {
  width: 312px;
  height: 100%;
  transition: all 0.2s;
  transform: translate(312px);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #FFF;
}

.zdo_drawer_menu .zdo_drawer_nav_wrapper.open {
  transform: translate(0);
}

.zdo_drawer_menu.left .zdo_drawer_button {
  right: auto;
  left: 32px;
}

.zdo_drawer_menu.left .zdo_drawer_nav_wrapper {
  transform: translate(-312px);
  right: auto;
  left: 0;
}

.zdo_drawer_menu.left .zdo_drawer_nav_wrapper.open {
  transform: translate(0);
}

/*+++ Default Navigation CSS +++*/
.zdo_drawer_menu .zdo_drawer_nav {
  padding: 112px 24px;
}

.zdo_drawer_menu .zdo_drawer_nav li {
  font-size: 16px;
  margin-bottom: 15px;
}

/*+++ Default Button Color +++*/
.zdo_drawer_menu .zdo_drawer_button {
  color: #276490;
}

.zdo_drawer_menu .zdo_drawer_button .zdo_drawer_bar {
  background-color: #276490;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  counter-reset: number 0;
  line-height: 2em;
  font-weight: 500;
  letter-spacing: 1.0px;
  color: #171717;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 0.9375rem;
  /*
	@media screen and (min-width:835px){
	background: url(../images/common/bg01.png) no-repeat right top;
	background-size: cover;
	background-attachment: fixed;
	}
	*/
}

@media only screen and (max-width: 640px) {
  body {
    font-size: 0.9375rem;
    line-height: 1.8em;
    letter-spacing: normal;
  }
}

.contents-inner {
  /*
	background:rgba(#fff,0.6);
	padding:2%;
	*/
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .inner {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 835px) and (max-width: 1000px) {
  .inner {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media only screen and (max-width: 835px) {
  .inner {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/*
.inner2 {
	margin: 0 auto;
	max-width: 1000px;
}
*/
#contents_wrap {
  width: 100%;
  min-width: 100% !important;
}

#contents {
  font-size: 100%;
  width: 100%;
  max-width: 1200px;
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  #contents {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (min-width: 835px) and (max-width: 1000px) {
  #contents {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  #contents {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 640px) {
  #contents {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

/*
#side {
	width: 18.75000%;
}
*/
.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

.policy h2 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.policy p {
  margin: 0 0 20px;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfixed table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", "a-otf-ryumin-pr6n", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}

header#global_header #header-wrap {
  width: 100%;
}

@media only screen and (max-width: 835px) {
  header#global_header #header-wrap {
    padding-top: 48px;
  }
}

.sm-logo {
  position: fixed;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  background: #fff;
  padding: 5px;
}

@media screen and (min-width: 836px) {
  .sm-logo {
    display: none;
  }
}

.sm-logo img {
  margin: 0 auto 0 0;
  width: 170px;
  display: block;
}

.done .st0 {
  fill: none;
}

@media all and (-ms-high-contrast: none) {
  .loading {
    display: none !important;
  }
}

.loading {
  background: #0A7AC5;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #3BCDBC, #0A7AC5);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #3BCDBC, #0A7AC5);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 99999999999999;
}

.loading svg {
  padding-top: 140px;
}

#main_txt {
  display: block;
  position: fixed;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 999999999;
}

#header {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 1600px) {
  #header .header-logo {
    min-width: 150px !important;
  }
}

@media screen and (max-width: 1300px) {
  #header {
    flex-direction: column;
  }
}

@media only screen and (max-width: 835px) {
  #header {
    display: none;
  }
}

#header .header-logo {
  width: 25%;
  max-width: 400px;
  min-width: 200px;
  margin-right: 10px;
}

#header .header-logo a {
  display: flex;
  align-items: center;
  color: #a3a3a3;
  font-size: 0.8125rem;
  font-weight: 500;
}

#header .header-logo a img {
  margin: 10px;
}

@media screen and (max-width: 1500px) {
  #header .header-logo a {
    flex-direction: column;
  }
  #header .header-logo a img {
    margin: 10px 0 0 0;
  }
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  #header .header-logo {
    width: 13%;
  }
}

@media only screen and (max-width: 835px) {
  #header .header-logo {
    width: 100%;
    min-width: auto;
  }
}

.header-right {
  width: 100%;
  max-width: 1300px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media only screen and (max-width: 835px) {
  .header-right {
    display: none;
  }
}

.header_info {
  width: 400px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.header_info div {
  width: 50%;
}

@media screen and (min-width: 1001px) and (max-width: 1200px) {
  .header_info {
    width: 300px;
  }
}

@media screen and (max-width: 1300px) {
  .header_info {
    position: absolute;
    top: 0;
  }
}

.header_link {
  padding: 0 10px;
}

.header_link img {
  width: 200px;
  max-width: auto;
}

.header_cont {
  font-size: 1rem;
}

.header_cont a {
  padding: 10px;
  line-height: 50px;
  display: block;
  color: #171717;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  background: #f8e516;
  font-family: "Roboto", sans-serif;
}

.header_cont a i {
  margin-right: 10px;
}

.header_cont a:hover {
  background: #f0d51c;
  transition: 0.5s;
  opacity: 1;
}

.header_cont a:hover i {
  transform: translate(5px, 0);
  transition: 0.5s;
}

.mean-container .mean-push {
  display: none !important;
}

#nav-wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 835px) {
  #nav-wrap {
    display: none;
  }
}

#nav_global ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

#nav_global ul li {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
}

#nav_global ul li a {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  text-align: center;
  position: relative;
  text-decoration: none;
  color: #171717;
  font-size: 1rem;
  font-weight: 600;
}

#nav_global ul li a:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #3a90d0;
  transform: scale(0, 1);
  transform-origin: left top;
  transition: transform .3s;
  z-index: 10;
}

#nav_global ul li a:hover:after {
  transform: scale(1, 1);
}

#nav_global ul li a:hover {
  opacity: 1;
  color: #3a90d0;
  transition: 0.5s;
}

@media screen and (max-width: 1600px) {
  #nav_global ul li a {
    font-size: 0.9375rem;
  }
}

@media screen and (max-width: 1400px) {
  #nav_global ul li a {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1300px) {
  #nav_global ul li a {
    font-size: 0.9375rem;
  }
}

@media screen and (max-width: 1000px) {
  #nav_global ul li a {
    font-size: 0.875rem;
  }
}

#nav_global ul li ul {
  position: absolute;
  background: rgba(241, 232, 232, 0.9);
  margin: auto;
  display: block;
  width: 100%;
  z-index: 1;
}

#nav_global ul li ul li:before {
  content: none !important;
}

#mainArea {
  max-width: 100%;
}

#mainArea ul li img {
  height: 100vh !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

#mainArea .no_move_main_txt {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 1200px;
  width: 100%;
  max-height: 510px;
  height: 100%;
  min-height: 300px;
  margin: auto;
}

@media only screen and (max-width: 835px) {
  #mainArea .no_move_main_txt {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: -20%;
  }
}

@media only screen and (max-width: 835px) {
  #mainArea .no_move_main_txt img {
    width: 80% !important;
  }
}

#mainArea .slider img {
  width: 100%;
}

.slick-dotted.slick-slider {
  margin-bottom: 0 !important;
}

.mean-container .mean-bar {
  background: #3a90d0 !important;
  position: fixed !important;
}

.mean-container .mean-nav {
  background: #3a90d0 !important;
}

.mean-container .mean-nav ul li a {
  border-top: 1px solid #fff !important;
  padding: 0.9em 5% !important;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  /*
	background-color: #e9832f !important;*/
}

.mean-container .mean-nav ul li a.mean-expand {
  height: 20px !important;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .mean-container .mean-nav ul li a.mean-expand {
    height: 23px !important;
  }
}

.mean-container .mean-nav ul li li a {
  padding: 0.5em 10% !important;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  position: fixed;
  top: 10px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #3a90d0;
}

@media only screen and (max-width: 640px) {
  .toggleMenu {
    width: 49px;
  }
}

.hide {
  transform: translateY(-200%);
}

.toggleMenu .bars {
  margin: 0 30px 0 15px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 3px;
  background-color: #fff;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 60%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  z-index: 10;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  min-width: 300px;
}

@media only screen and (max-width: 640px) {
  .overlayMenu {
    width: 80%;
    min-width: 300px;
  }
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #ebf2f7;
  padding: 80px 30px !important;
}

.overlayMenu > .outer li {
  position: relative;
}

.overlayMenu > .outer li a {
  color: #171717;
  padding: 15px;
  display: block;
  border-bottom: 1px solid rgba(58, 144, 208, 0.5);
  font-size: 16px;
}

.overlayMenu > .outer .toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  display: block;
  padding: 29px 15px;
}

.overlayMenu > .outer .sp_btn {
  position: relative;
}

.overlayMenu > .outer .sp_btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 12px;
  height: 1px;
  background-color: #3a90d0;
  content: '';
}

.overlayMenu > .outer .sp_btn:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 12px;
  height: 1px;
  background-color: #3a90d0;
  content: '';
  transition: transform .3s;
  transform: rotate(-90deg);
}

.overlayMenu > .outer .on_click:after {
  transform: rotate(0);
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 835px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 835px) {
  .smOnly {
    display: none !important;
  }
}

.anchor {
  display: block;
  transform: translateY(-100px);
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .anchor {
    transform: translateY(-100px);
  }
}

@media only screen and (max-width: 640px) {
  .anchor {
    transform: translateY(-60px);
  }
}

/*
飛ばしたいところの前に以下のような記述
<span id="anc1" class="anchor"></span>
*/
.clone-nav {
  width: 100%  !important;
  max-width: 100% !important;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: .3s;
  transform: translateY(-100%);
  margin-left: 0 !important;
}

@media only screen and (max-width: 835px) {
  .clone-nav {
    display: none !important;
  }
}

.is-show {
  transform: translateY(0);
}

footer#global_footer {
  position: relative;
  background: none !important;
  min-width: auto !important;
  margin-top: 80px;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  footer#global_footer {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer {
    margin-top: 30px;
  }
}

#top-button {
  position: fixed;
  z-index: 1000000;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: bottom;
  width: 100%;
  max-width: 450px;
}

@media screen and (max-width: 836px) {
  #top-button {
    direction: none;
  }
}

#top-button .sm_link {
  width: 50%;
}

#top-button .sm_link a {
  display: block;
  width: 100%;
}

#top-button .sm_link a img {
  width: 100%;
}

#top-button .sm_cont {
  width: 50%;
}

#top-button .sm_cont a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #171717;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  background: #f8e516;
  font-family: "Roboto", sans-serif;
}

#top-button .sm_cont a i {
  margin-right: 10px;
}

#top-button .sm_cont a:hover {
  background: #f0d51c;
  transition: 0.5s;
  opacity: 1;
}

#top-button .sm_cont a:hover i {
  transform: translate(5px, 0);
  transition: 0.5s;
}

.footer-wrap {
  background: #171717;
  padding: 30px 15px 100px;
}

@media only screen and (max-width: 835px) {
  .footer-wrap {
    padding: 15px 15px 80px;
  }
}

.footer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  color: #fff;
  text-align: left;
}

.footer a {
  color: #fff;
}

.footer .footer-info .footer-logo {
  margin-bottom: 10px;
  width: 95%;
  max-width: 300px;
  min-width: 280px;
}

.footer .footer-info .footer-logo a {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.footer .footer-info .footer-logo a img {
  margin: 10px;
}

.footer .footer-nav {
  width: 100%;
  max-width: 500px;
}

.footer .footer-nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.footer .footer-nav ul li {
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.footer .footer-nav ul li a {
  display: block;
  text-align: right;
  padding: 5px;
}

@media only screen and (max-width: 640px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer .footer-info .footer-logo {
    margin: 0 auto;
  }
  .footer .footer-info .footer-logo a {
    flex-direction: column;
  }
  .footer .footer-info .footer-logo a img {
    margin: 0;
  }
}

#copyright {
  text-align: center;
  width: 175px;
  color: #fff !important;
}

#copyright a {
  color: #fff !important;
}

.mean-container .mean-push {
  display: none !important;
}

.title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
  font-size: 3.125rem;
  font-weight: 700;
  color: #171717;
}

@media (max-width: 1200px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: calc(1.4375rem + 2.25vw) ;
  }
}

@media screen and (max-width: 1600px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1200px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

@media screen and (max-width: 1300px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 1300px) and (max-width: 1200px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: calc(1.34375rem + 1.125vw) ;
  }
}

@media screen and (max-width: 1100px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 1100px) and (max-width: 1200px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: calc(1.3125rem + 0.75vw) ;
  }
}

@media screen and (max-width: 1000px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 1000px) and (max-width: 1200px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: calc(1.2875rem + 0.45vw) ;
  }
}

@media only screen and (max-width: 835px) {
  .title_01 h1, .title_02 h1, .title_01 h2, .title_02 h2 {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.title_02 h1, .title_02 h2 {
  color: #171717;
  text-align: left;
  line-height: 1;
}

.title_03, .title_03b, .title_03c, .title_03e, .title_03d, .title_04, .title_04b {
  position: relative;
}

.title_03 h2, .title_03b h2, .title_03c h2, .title_03e h2, .title_03d h2, .title_04 h2, .title_04b h2 {
  font-size: 1.875rem;
  font-weight: 600;
}

@media (max-width: 1200px) {
  .title_03 h2, .title_03b h2, .title_03c h2, .title_03e h2, .title_03d h2, .title_04 h2, .title_04b h2 {
    font-size: calc(1.3125rem + 0.75vw) ;
  }
}

@media screen and (max-width: 1500px) {
  .title_03 h2, .title_03b h2, .title_03c h2, .title_03e h2, .title_03d h2, .title_04 h2, .title_04b h2 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 1500px) and (max-width: 1200px) {
  .title_03 h2, .title_03b h2, .title_03c h2, .title_03e h2, .title_03d h2, .title_04 h2, .title_04b h2 {
    font-size: calc(1.3rem + 0.6vw) ;
  }
}

@media screen and (max-width: 1000px) {
  .title_03 h2, .title_03b h2, .title_03c h2, .title_03e h2, .title_03d h2, .title_04 h2, .title_04b h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1000px) and (max-width: 1200px) {
  .title_03 h2, .title_03b h2, .title_03c h2, .title_03e h2, .title_03d h2, .title_04 h2, .title_04b h2 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

@media only screen and (max-width: 835px) {
  .title_03 h2, .title_03b h2, .title_03c h2, .title_03e h2, .title_03d h2, .title_04 h2, .title_04b h2 {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.title_03 img, .title_03b img, .title_03c img, .title_03e img, .title_03d img, .title_04 img, .title_04b img {
  width: 18%;
  position: absolute;
  bottom: 50%;
  left: -5%;
}

@media only screen and (max-width: 640px) {
  .title_03 img, .title_03b img, .title_03c img, .title_03e img, .title_03d img, .title_04 img, .title_04b img {
    width: 30%;
  }
}

.title_03b img {
  width: 50%;
  bottom: 54%;
}

@media screen and (max-width: 1200px) {
  .title_03b img {
    width: 60%;
  }
}

@media only screen and (max-width: 835px) {
  .title_03b {
    margin-top: 15%;
  }
  .title_03b img {
    bottom: 50%;
    width: 40%;
  }
}

@media only screen and (max-width: 640px) {
  .title_03b img {
    width: 40%;
    left: 0;
  }
}

.title_03c img {
  width: 70%;
  bottom: 70%;
}

@media only screen and (max-width: 835px) {
  .title_03c {
    margin-top: 15%;
    bottom: 50%;
  }
  .title_03c img {
    left: 0%;
    width: 50%;
  }
}

.title_03e img {
  width: 50%;
  bottom: 90%;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .title_03e {
    margin-top: 15%;
  }
  .title_03e img {
    width: 30%;
    left: 0%;
  }
}

@media only screen and (max-width: 640px) {
  .title_03e {
    margin-top: 15%;
  }
  .title_03e img {
    bottom: auto;
    top: -30%;
    width: 50%;
    left: 0%;
  }
}

@media screen and (max-width: 550px) {
  .title_03e img {
    top: -20%;
  }
}

@media screen and (max-width: 450px) {
  .title_03e img {
    top: -10%;
  }
}

.title_03d {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (min-width: 835px) and (max-width: 1260px) {
  .title_03d {
    margin-left: 12%;
  }
}

.title_03d h2 {
  text-align: left;
  z-index: 1;
}

@media only screen and (max-width: 835px) {
  .title_03d h2 {
    padding-left: 30px;
  }
}

.title_03d img {
  width: 20% !important;
  bottom: 0;
  left: -12%;
  z-index: -1;
}

@media only screen and (max-width: 835px) {
  .title_03d img {
    left: 0;
    bottom: 50%;
  }
}

@media only screen and (max-width: 835px) {
  .title_03d img {
    width: 35%;
  }
}

.title_04 img, .title_04b img {
  width: 30% !important;
  bottom: 40%;
}

.title_04 h2, .title_04b h2 {
  color: #171717;
}

@media only screen and (max-width: 835px) {
  .title_04, .title_04b {
    margin-top: 15%;
  }
  .title_04 img, .title_04b img {
    bottom: 50%;
  }
}

@media only screen and (max-width: 640px) {
  .title_04 img, .title_04b img {
    width: 45%;
  }
}

.title_04b img {
  left: 0;
  bottom: 50%;
}

.page_title table td {
  padding: 5px !important;
}

.page_title h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
}

@media (max-width: 1200px) {
  .page_title h1 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

.page_title h1 span {
  color: #3a90d0;
}

.page_title .sub {
  font-family: "Roboto", sans-serif;
  color: #ccc;
  font-size: 1.25rem;
}

.page_title .sub div {
  font-weight: 600;
}

.page_title img {
  width: 30px;
}

.page_title i {
  font-size: 1.5625rem;
}

@media (max-width: 1200px) {
  .page_title i {
    font-size: calc(1.28125rem + 0.375vw) ;
  }
}

.page_title_sub h1, .page_title_sub h2 {
  font-size: 2.5rem;
  color: #171717;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

@media screen and (max-width: 1600px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 1600px) and (max-width: 1200px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: calc(1.34375rem + 1.125vw) ;
  }
}

@media screen and (max-width: 1300px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 1300px) and (max-width: 1200px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: calc(1.3125rem + 0.75vw) ;
  }
}

@media screen and (max-width: 1100px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 1100px) and (max-width: 1200px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: calc(1.3125rem + 0.75vw) ;
  }
}

@media screen and (max-width: 1000px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 1000px) and (max-width: 1200px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: calc(1.2875rem + 0.45vw) ;
  }
}

@media only screen and (max-width: 835px) {
  .page_title_sub h1, .page_title_sub h2 {
    font-size: 1.25rem;
    font-weight: 500;
  }
}

.blog_page_title {
  text-align: center;
}

.blog_page_title table td {
  padding: 5px !important;
  border: none;
}

.blog_page_title .blog_ttl {
  font-size: 2.5rem;
  font-weight: 600;
  color: #fff;
}

@media (max-width: 1200px) {
  .blog_page_title .blog_ttl {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

.blog_page_title .blog_ttl span {
  color: #43c6ac;
}

.blog_page_title .sub {
  font-family: "Roboto", sans-serif;
  color: #ccc;
  font-size: 1.125rem;
}

.blog_page_title .sub div {
  font-weight: 600;
}

.blog_page_title img {
  width: 30px;
}

.blog_page_title i {
  font-size: 1.5625rem;
}

@media (max-width: 1200px) {
  .blog_page_title i {
    font-size: calc(1.28125rem + 0.375vw) ;
  }
}

.title_a h2, .title_a h3 {
  font-size: 1.5rem;
  color: #171717;
  font-weight: 600;
  border-bottom: 2px solid #333;
  border-image: linear-gradient(to right, #3a90d0 0%, #43c6ac);
  border-image-slice: 1;
  padding: 7px 0 7px 10px;
}

@media (max-width: 1200px) {
  .title_a h2, .title_a h3 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .title_a h2, .title_a h3 {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 640px) {
  .title_a h2, .title_a h3 {
    font-size: 1.125rem;
  }
}

.title_a h2 .sub, .title_a h3 .sub {
  font-size: 1rem;
  color: #171717;
}

.title_b h2, .title_b h3, .title_b h4 {
  position: relative;
  padding-left: 25px;
  font-size: 1.5rem;
  font-weight: 600;
  color: #171717;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #333;
  border-image: linear-gradient(to right, #3a90d0 0%, #43c6ac);
  border-image-slice: 1;
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .title_b h2, .title_b h3, .title_b h4 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.title_b h2:after, .title_b h3:after, .title_b h4:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f069";
  /*アイコンのユニコード*/
  font-weight: 600;
  color: #3a90d0;
  font-size: 1.25rem;
  position: absolute;
  /*絶対位置*/
  left: 0px;
  /*アイコンの位置*/
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 835px) {
  .title_b h2, .title_b h3, .title_b h4 {
    font-size: 1.125rem;
    font-weight: bold;
  }
}

.title_c h3, .title_c h4 {
  font-size: 1.125rem;
  color: #fff;
  background: #3a90d0;
  padding: 5px 10px;
}

.title_d h2, .title_d h3, .title_d h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #171717;
  background: rgba(255, 255, 255, 0.5);
  border-left: 5px solid #f8e516;
  border-bottom: 3px solid #cccccc;
  padding: 5px 10px;
}

.title_e h3, .title_e h4 {
  position: relative;
  padding-left: 20px;
  font-weight: 500;
  color: #171717;
  border-bottom: dotted 1px rgba(248, 229, 22, 0.5);
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.title_e h3:after, .title_e h4:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f04b";
  /*アイコンのユニコード*/
  font-weight: 600;
  color: #3a90d0;
  position: absolute;
  /*絶対位置*/
  left: 0px;
  /*アイコンの位置*/
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.title_center1 h2, .title_center1 h3 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  display: block;
  padding: 15px;
  width: 100%;
  background-image: -moz-linear-gradient(151deg, #43c6ac 0%, #0168b3 100%);
  background-image: -webkit-linear-gradient(151deg, #43c6ac 0%, #0168b3 100%);
  background-image: -ms-linear-gradient(151deg, #43c6ac 0%, #0168b3 100%);
  color: #fff;
}

@media (max-width: 1200px) {
  .title_center1 h2, .title_center1 h3 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.title_center1 h2 a, .title_center1 h3 a {
  color: #fff;
  font-weight: 600;
  text-decoration: underline !important;
}

@media only screen and (max-width: 835px) {
  .title_center1 h2, .title_center1 h3 {
    font-size: 1.25rem;
  }
}

.title_center1b h2, .title_center1b h3 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  display: block;
  padding: 10px;
  width: 100%;
  border: 2px solid #ccc;
}

@media (max-width: 1200px) {
  .title_center1b h2, .title_center1b h3 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.title_center1b h2 a, .title_center1b h3 a {
  color: #fff;
  font-weight: 600;
  text-decoration: underline !important;
}

@media only screen and (max-width: 835px) {
  .title_center1b h2, .title_center1b h3 {
    font-size: 1.25rem;
  }
}

.title_center2 h2, .title_center2 h3 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  display: block;
  padding: 10px;
  width: 100%;
  background: #3a90d0;
  color: #fff;
}

@media (max-width: 1200px) {
  .title_center2 h2, .title_center2 h3 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.title_center2 h2 a, .title_center2 h3 a {
  color: #fff;
  font-weight: 600;
  text-decoration: underline !important;
}

@media only screen and (max-width: 835px) {
  .title_center2 h2, .title_center2 h3 {
    font-size: 1.25rem;
  }
}

.title_center2b h2, .title_center2b h3 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  display: block;
  padding: 10px;
  width: 100%;
  background: #3a90d0;
  color: #fff;
}

@media (max-width: 1200px) {
  .title_center2b h2, .title_center2b h3 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.title_center2b h2 a, .title_center2b h3 a {
  color: #fff;
  font-weight: 600;
  text-decoration: underline !important;
}

.anim_txt1, .anim_txt2, .anim_txt3, .anim_txt4, .anim_txt5, .anim_txt6 {
  transition: opacity 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(-100%, 0, 0);
  position: absolute;
  left: 0;
  top: 60px;
  padding: 24% 3%;
  height: 100%;
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .anim_txt1, .anim_txt2, .anim_txt3, .anim_txt4, .anim_txt5, .anim_txt6 {
    top: 30px;
  }
}

.anim_txt1 img, .anim_txt2 img, .anim_txt3 img, .anim_txt4 img, .anim_txt5 img, .anim_txt6 img {
  width: 80% !important;
}

@media only screen and (max-width: 835px) {
  .anim_txt1, .anim_txt2, .anim_txt3, .anim_txt4, .anim_txt5, .anim_txt6 {
    transform: none;
    display: none;
  }
}

.cont_01_flx a, .cont_01_flx_b a {
  overflow: hidden;
}

.cont_01_flx a:hover, .cont_01_flx_b a:hover {
  opacity: 1;
}

.cont_01_flx_b a {
  overflow: hidden;
}

.cont_01_flx_b a:hover {
  opacity: 1;
}

.mb_cont_01, .mb_cont_02, .mb_cont_03, .mb_cont_04, .mb_cont_05, .mb_cont_06 {
  background: url(../images/contents/saitou_local_main2.jpg) no-repeat right bottom;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 80px 10px;
}

@media only screen and (max-width: 835px) {
  .mb_cont_01, .mb_cont_02, .mb_cont_03, .mb_cont_04, .mb_cont_05, .mb_cont_06 {
    padding: 80% 10px 0;
    margin-bottom: 30%;
    background-position: 96%;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .mb_cont_01, .mb_cont_02, .mb_cont_03, .mb_cont_04, .mb_cont_05, .mb_cont_06 {
    padding: 70% 10px 0;
    margin-bottom: 40%;
  }
}

.mb_cont_01 .in, .mb_cont_02 .in, .mb_cont_03 .in, .mb_cont_04 .in, .mb_cont_05 .in, .mb_cont_06 .in {
  padding-left: 5%;
}

@media only screen and (max-width: 835px) {
  .mb_cont_01 .in, .mb_cont_02 .in, .mb_cont_03 .in, .mb_cont_04 .in, .mb_cont_05 .in, .mb_cont_06 .in {
    background: rgba(58, 144, 208, 0.5);
    padding: 30px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .mb_cont_01 .in, .mb_cont_02 .in, .mb_cont_03 .in, .mb_cont_04 .in, .mb_cont_05 .in, .mb_cont_06 .in {
    background: rgba(58, 144, 208, 0.5);
    padding: 30px 50px;
    margin: 0 auto;
  }
}

.mb_cont_01 .in .name, .mb_cont_02 .in .name, .mb_cont_03 .in .name, .mb_cont_04 .in .name, .mb_cont_05 .in .name, .mb_cont_06 .in .name {
  font-size: 2em;
  color: #fff;
  background: #171717;
  padding: 15px;
  border: 1px solid #fff;
  font-weight: bold;
  max-width: 700px;
  width: 100%;
  display: inline-block;
}

.mb_cont_01 .in .name span, .mb_cont_02 .in .name span, .mb_cont_03 .in .name span, .mb_cont_04 .in .name span, .mb_cont_05 .in .name span, .mb_cont_06 .in .name span {
  color: #a6daef;
  margin-left: 15px;
  font-size: 16px;
  font-weight: bold;
}

@media only screen and (max-width: 835px) {
  .mb_cont_01 .in .name span, .mb_cont_02 .in .name span, .mb_cont_03 .in .name span, .mb_cont_04 .in .name span, .mb_cont_05 .in .name span, .mb_cont_06 .in .name span {
    display: block;
    margin-left: 0;
    margin-top: 15px;
  }
}

.mb_cont_01 .in .job div, .mb_cont_02 .in .job div, .mb_cont_03 .in .job div, .mb_cont_04 .in .job div, .mb_cont_05 .in .job div, .mb_cont_06 .in .job div {
  font-size: 1.23em;
  color: #fff;
  margin-top: 10px;
  max-width: 700px;
  width: 100%;
  display: inline-block;
}

.mb_cont_02 {
  background: url(../images/contents/chiba_local_main2.jpg) no-repeat right;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 835px) {
  .mb_cont_02 {
    background-position: 96%;
  }
}

.mb_cont_02 .in {
  padding-left: 5%;
}

@media only screen and (max-width: 835px) {
  .mb_cont_02 .in {
    background: rgba(56, 163, 220, 0.5);
    padding: 30px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .mb_cont_02 .in {
    background: rgba(56, 163, 220, 0.5);
    padding: 30px 50px;
    margin: 0 auto;
  }
}

.mb_cont_03 {
  background: url(../images/contents/ishikawa_local_main2.jpg) no-repeat right;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 835px) {
  .mb_cont_03 {
    background-position: 90%;
  }
}

.mb_cont_03 .in {
  padding-left: 5%;
}

@media only screen and (max-width: 835px) {
  .mb_cont_03 .in {
    background: rgba(36, 182, 177, 0.5);
    padding: 30px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .mb_cont_03 .in {
    background: rgba(36, 182, 177, 0.5);
    padding: 30px 50px;
    margin: 0 auto;
  }
}

.mb_cont_04 {
  background: url(../images/contents/kuki_local_main2.jpg) no-repeat right;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 835px) {
  .mb_cont_04 {
    background-position: 90%;
  }
}

.mb_cont_04 .in {
  padding-left: 5%;
}

@media only screen and (max-width: 835px) {
  .mb_cont_04 .in {
    background: rgba(230, 227, 73, 0.5);
    padding: 30px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .mb_cont_04 .in {
    background: rgba(230, 227, 73, 0.5);
    padding: 30px 50px;
    margin: 0 auto;
  }
}

.mb_cont_05 {
  background: url(../images/contents/sootome_local_main2.jpg) no-repeat right;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 835px) {
  .mb_cont_05 {
    background-position: 90%;
  }
}

.mb_cont_05 .in {
  padding-left: 5%;
}

@media only screen and (max-width: 835px) {
  .mb_cont_05 .in {
    background: rgba(6, 161, 220, 0.5);
    padding: 30px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .mb_cont_05 .in {
    background: rgba(6, 161, 220, 0.5);
    padding: 30px 50px;
    margin: 0 auto;
  }
}

.mb_cont_06 {
  background: url(../images/contents/nishimura_local_main2.jpg) no-repeat right;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 835px) {
  .mb_cont_06 {
    background-position: 90%;
  }
}

.mb_cont_06 .in {
  padding-left: 5%;
}

@media only screen and (max-width: 835px) {
  .mb_cont_06 .in {
    background: rgba(116, 201, 216, 0.5);
    padding: 30px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .mb_cont_06 .in {
    background: rgba(116, 201, 216, 0.5);
    padding: 30px 50px;
    margin: 0 auto;
  }
}

.cont_01_wrap {
  margin: 12% auto 12% auto;
  position: relative;
}

.cont_01_wrap .cont_01 {
  position: absolute;
  top: -8%;
  right: 0;
  z-index: 1;
  width: 56%;
  max-width: 1000px;
  padding: 0 1%;
}

.cont_01_wrap .cont_01 table td {
  padding: 0 !important;
}

.cont_01_wrap .cont_01 table td img {
  width: 50%;
}

@media screen and (max-width: 1000px) {
  .cont_01_wrap .cont_01 table td img {
    width: 55%;
  }
}

.cont_01_wrap .cont_01 .cont_txt_wrap > div .title_01, .cont_01_wrap .cont_01 .cont_txt_wrap > div .title_02 {
  width: 100%;
  margin: 20px 0;
}

.cont_01_wrap .cont_01 .cont_txt_wrap > div .title_01 h2, .cont_01_wrap .cont_01 .cont_txt_wrap > div .title_02 h2 {
  display: block;
  width: 100%;
}

.cont_01_wrap .cont_01 .cont_txt_wrap > div .cont_01_txt {
  width: 100%;
}

.cont_01_wrap .cont_01_btn_wrap {
  position: absolute;
  bottom: 5%;
  left: 0;
  z-index: 1;
  width: 56%;
}

@media screen and (max-width: 1850px) {
  .cont_01_wrap .cont_01 {
    top: -10%;
  }
}

@media screen and (max-width: 1700px) {
  .cont_01_wrap {
    margin: 15%  auto 15% auto;
  }
  .cont_01_wrap .cont_01 {
    top: -13%;
  }
}

@media screen and (max-width: 1400px) {
  .cont_01_wrap {
    margin: 17%  auto 5% auto;
  }
}

@media screen and (max-width: 1200px) {
  .cont_01_wrap {
    margin: 20%  auto 5% auto;
  }
  .cont_01_wrap .cont_01 .cont_txt_wrap > div {
    flex-direction: column;
    align-items: flex-start;
  }
  .cont_01_wrap .cont_01 .cont_txt_wrap > div .title_01, .cont_01_wrap .cont_01 .cont_txt_wrap > div .title_02 {
    width: 100%;
    margin: 10px 0;
  }
  .cont_01_wrap .cont_01 .cont_txt_wrap > div .title_01 h2, .cont_01_wrap .cont_01 .cont_txt_wrap > div .title_02 h2 {
    text-align: left;
  }
  .cont_01_wrap .cont_01 .cont_txt_wrap > div .cont_01_txt {
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .cont_01_wrap {
    margin: 30% 0 10% auto;
  }
  .cont_01_wrap .cont_01 {
    top: -30%;
    width: 60%;
  }
  .cont_01_wrap .cont_01_btn_wrap {
    bottom: -5%;
  }
}

@media screen and (max-width: 835px) {
  .cont_01_wrap {
    margin: 35% 0 15% auto;
  }
  .cont_01_wrap .cont_01 {
    top: -35%;
    width: 60%;
  }
  .cont_01_wrap .cont_01_btn_wrap {
    bottom: -10%;
  }
}

@media screen and (max-width: 700px) {
  .cont_01_wrap {
    margin: 40% 0 15% auto;
  }
  .cont_01_wrap .cont_01 {
    top: -35%;
    width: 60%;
  }
  .cont_01_wrap .cont_01_btn_wrap {
    bottom: -10%;
  }
}

@media screen and (max-width: 640px) {
  .cont_01_wrap {
    margin: 10% 0;
  }
  .cont_01_wrap .cont_01 {
    position: static;
    width: 95%;
    margin: 0 auto 15px auto;
  }
  .cont_01_wrap .cont_01_btn_wrap {
    position: static;
    width: 100%;
    margin-top: 15px;
  }
}

.cont_01_flx, .cont_01_flx_b {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.cont_01_flx a, .cont_01_flx_b a {
  position: relative;
}

.cont_01_flx a:first-child, .cont_01_flx_b a:first-child {
  width: 44%;
}

.cont_01_flx a:nth-child(2), .cont_01_flx_b a:nth-child(2), .cont_01_flx a:nth-child(3), .cont_01_flx_b a:nth-child(3) {
  width: 28%;
}

.cont_01_flx a .box article > h3, .cont_01_flx_b a .box article > h3 {
  display: none;
}

.cont_01_flx a .box article > div, .cont_01_flx_b a .box article > div {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 400px;
  width: 90%;
}

.cont_01_flx a .box article > div .group, .cont_01_flx_b a .box article > div .group {
  background: #171717;
  color: #fff;
  padding: 3px 10px;
  display: block;
  width: 200px;
  text-align: center;
  font-size: 0.8125rem;
}

.cont_01_flx a .box article > div h3, .cont_01_flx_b a .box article > div h3 {
  background: #fefefe;
  padding: 5px 5px 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #171717;
}

.cont_01_flx a .box article > div h3 .year, .cont_01_flx_b a .box article > div h3 .year {
  font-size: 0.8125rem;
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_01_flx a .box article > div h3 .year, .cont_01_flx_b a .box article > div h3 .year {
    line-height: 1em;
  }
}

.cont_01_flx a .box article > div h3 div, .cont_01_flx_b a .box article > div h3 div {
  font-size: 1.875rem;
  font-weight: bold;
}

@media (max-width: 1200px) {
  .cont_01_flx a .box article > div h3 div, .cont_01_flx_b a .box article > div h3 div {
    font-size: calc(1.3125rem + 0.75vw) ;
  }
}

.cont_01_flx a .box article > div h3 div .en, .cont_01_flx_b a .box article > div h3 div .en {
  color: #3a90d0;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  padding-left: 10px;
}

@media screen and (min-width: 835px) and (max-width: 1200px) {
  .cont_01_flx a .box article > div h3 div .en, .cont_01_flx_b a .box article > div h3 div .en {
    line-height: 1em;
  }
}

@media screen and (max-width: 1500px) {
  .cont_01_flx a .box article > div h3 div, .cont_01_flx_b a .box article > div h3 div {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 1500px) and (max-width: 1200px) {
  .cont_01_flx a .box article > div h3 div, .cont_01_flx_b a .box article > div h3 div {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

@media screen and (max-width: 1500px) {
  .cont_01_flx a .box article > div h3 div .en, .cont_01_flx_b a .box article > div h3 div .en {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 1200px) {
  .cont_01_flx a .box article > div h3 div, .cont_01_flx_b a .box article > div h3 div {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 1000px) {
  .cont_01_flx a:first-child, .cont_01_flx_b a:first-child {
    width: 40%;
  }
  .cont_01_flx a:nth-child(2), .cont_01_flx_b a:nth-child(2), .cont_01_flx a:nth-child(3), .cont_01_flx_b a:nth-child(3) {
    width: 30%;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .cont_01_flx a .box article > div .group, .cont_01_flx_b a .box article > div .group {
    padding: 0;
    width: 100%;
  }
  .cont_01_flx a .box article > div h3, .cont_01_flx_b a .box article > div h3 {
    padding: 3px;
    line-height: 1.8em;
  }
  .cont_01_flx a .box article > div h3 div, .cont_01_flx_b a .box article > div h3 div {
    font-size: 1.125rem;
    text-align: center;
  }
  .cont_01_flx a .box article > div h3 div .en, .cont_01_flx_b a .box article > div h3 div .en {
    display: block;
    padding-left: 0;
  }
}

@media only screen and (max-width: 640px) {
  .cont_01_flx a:first-child, .cont_01_flx_b a:first-child {
    width: 100%;
  }
  .cont_01_flx a:nth-child(2), .cont_01_flx_b a:nth-child(2), .cont_01_flx a:nth-child(3), .cont_01_flx_b a:nth-child(3) {
    width: 50%;
  }
  .cont_01_flx a .box article > div, .cont_01_flx_b a .box article > div {
    max-width: 250px;
  }
  .cont_01_flx a .box article > div .group, .cont_01_flx_b a .box article > div .group {
    padding: 0;
    width: auto;
  }
  .cont_01_flx a .box article > div h3, .cont_01_flx_b a .box article > div h3 {
    padding: 0 3px;
    line-height: 1.5em;
  }
  .cont_01_flx a .box article > div h3 .year, .cont_01_flx_b a .box article > div h3 .year {
    font-size: 0.75rem;
  }
  .cont_01_flx a .box article > div h3 div, .cont_01_flx_b a .box article > div h3 div {
    font-size: 1.125rem;
    text-align: center;
  }
  .cont_01_flx a .box article > div h3 div .en, .cont_01_flx_b a .box article > div h3 div .en {
    display: block;
    padding-left: 0;
  }
}

.cont_01_flx_b {
  align-items: flex-start;
}

.cont_01_flx_b a:first-child, .cont_01_flx_b a:nth-child(2) {
  width: 28%;
}

.cont_01_flx_b a:nth-child(3) {
  width: 44%;
}

@media screen and (max-width: 1000px) {
  .cont_01_flx_b a:first-child, .cont_01_flx_b a:nth-child(2) {
    width: 30%;
  }
  .cont_01_flx_b a:nth-child(3) {
    width: 40%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_01_flx_b a:first-child, .cont_01_flx_b a:nth-child(2) {
    width: 50%;
  }
  .cont_01_flx_b a:nth-child(3) {
    width: 100%;
  }
}

.cont_01_txt {
  color: #171717;
  font-size: 1rem;
  line-height: 2em;
}

@media only screen and (max-width: 835px) {
  .cont_01_txt {
    font-size: 1rem;
    line-height: 1.6em;
  }
}

.cont_02 {
  max-width: 850px;
  margin: 0 auto 0 10%;
  width: 60%;
  background: rgba(255, 255, 255, 0.8);
  padding: 4% 50px;
  position: relative;
}

.cont_02:after {
  position: absolute;
  content: '';
  background: url(../images/common/topmessage.png) no-repeat;
  background-size: 100%;
  top: -15%;
  right: -15%;
  width: 60%;
  height: 100%;
}

.cont_02 .biglogo {
  position: absolute;
  right: -60%;
  top: 100%;
  width: 30%;
  max-width: 320px;
  padding-bottom: 50%;
}

.cont_02 .btn1, .cont_02 .btn1_jp, .cont_02 .btn1_jpb, .cont_02 .btn1_jp2, .cont_02 .btn1_jp_L, .cont_02 .btn1_rad30 {
  margin-top: 10px;
}

@media screen and (max-width: 1750px) {
  .cont_02 {
    width: 60%;
  }
  .cont_02:after {
    right: -2%;
  }
}

@media screen and (max-width: 1600px) {
  .cont_02 {
    margin: 0 auto 0 5%;
  }
  .cont_02 .biglogo {
    right: -50%;
  }
}

@media screen and (max-width: 1200px) {
  .cont_02 .biglogo {
    top: 80%;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .cont_02 .biglogo {
    top: 30%;
  }
  .cont_02:after {
    width: 60%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_02 {
    width: 90%;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
  }
  .cont_02 .biglogo {
    width: 20%;
    top: 70%;
    right: 5%;
  }
  .cont_02:after {
    width: 50%;
    right: auto;
    left: 18%;
    top: -30%;
  }
}

.cont_02a {
  display: flex;
  max-width: 800px;
  margin: 0 auto 0 10%;
  width: 70%;
  background: rgba(255, 255, 255, 0.8);
  padding: 3% 50px;
}

.cont_02a .topmessage_img {
  width: 80%;
  margin: 0 -20% 0 0;
}

.cont_02a .topmessage_img img {
  display: block;
  width: 80% !important;
  margin-left: 0 !important;
}

.cont_02a .cont_02_txt_w {
  width: 300px;
  margin: 15% 0 0 0;
}

.cont_02a .cont_02_txt_w .cont_02_txt {
  position: relative;
  z-index: 1;
}

.cont_02a .btn1, .cont_02a .btn1_jp, .cont_02a .btn1_jpb, .cont_02a .btn1_jp2, .cont_02a .btn1_jp_L, .cont_02a .btn1_rad30 {
  margin-top: 10px;
}

@media screen and (max-width: 1600px) {
  .cont_02a {
    margin: 0 auto 0 5%;
  }
}

@media screen and (max-width: 1200px) {
  .cont_02a .cont_02_txt_w {
    margin: 20% 0 0 0;
  }
}

@media only screen and (max-width: 835px) {
  .cont_02a {
    flex-direction: column;
  }
  .cont_02a .topmessage_img {
    width: 70%;
    margin: 0;
  }
  .cont_02a .cont_02_txt_w {
    margin: 0;
  }
}

@media only screen and (max-width: 640px) {
  .cont_02a {
    width: 90%;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
  }
}

.cont_02b {
  max-width: 1000px;
  margin: 0 auto 0 10%;
  width: 60%;
  background: rgba(255, 255, 255, 0.7);
  padding: 4% 50px;
  position: relative;
}

.cont_02b .biglogo {
  position: absolute;
  right: -50%;
  width: 50%;
  max-width: 600px;
  bottom: 0;
}

.cont_02b .btn1, .cont_02b .btn1_jp, .cont_02b .btn1_jpb, .cont_02b .btn1_jp2, .cont_02b .btn1_jp_L, .cont_02b .btn1_rad30 {
  margin-top: 10px;
}

@media screen and (max-width: 1600px) {
  .cont_02b {
    margin: 0 auto 0 5%;
  }
  .cont_02b .biglogo {
    bottom: auto;
    top: 30%;
  }
}

@media screen and (max-width: 1200px) {
  .cont_02b .biglogo {
    top: 20%;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .cont_02b {
    width: 80%;
  }
  .cont_02b .biglogo {
    top: 10%;
    width: 25%;
    right: -15%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_02b {
    width: 90%;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
  }
  .cont_02b .biglogo {
    top: 30%;
    width: 50%;
    right: 0%;
    opacity: 1;
    z-index: -1;
  }
}

.bg_img_wrap1 {
  position: relative;
  padding: 10% 0 !important;
  margin-bottom: 15%;
}

.bg_img_wrap1:after {
  content: '';
  background: url(../images/common/color_mark1.png) no-repeat;
  background-size: cover;
  position: absolute;
  right: 1%;
  bottom: -30%;
  width: 400px;
  height: 400px;
  z-index: -1;
}

@media only screen and (max-width: 835px) {
  .bg_img_wrap1:after {
    width: 250px;
    height: 250px;
    bottom: -10%;
  }
}

@media screen and (max-width: 1500px) {
  .bg_img_wrap1 {
    padding: 10% 0 !important;
  }
}

@media screen and (max-width: 1100px) {
  .bg_img_wrap1 {
    padding: 10% 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .bg_img_wrap1 {
    padding: 20% 0 5% 0 !important;
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 640px) {
  .bg_img_wrap1 {
    padding: 30% 0 5% 0 !important;
    margin-bottom: 10%;
  }
}

.bg_img_inr1 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/top_bg1.png), url(../images/common/color_box1.png);
  background-size: 80%, 23%;
  background-repeat: no-repeat;
  background-position: right top, bottom left;
  z-index: -1;
}

@media only screen and (max-width: 640px) {
  .bg_img_inr1 {
    background-size: 95%, 30%;
  }
}

.bg_img_wrap1a {
  position: relative;
  padding: 10% 0 !important;
  margin-bottom: 15%;
}

.bg_img_wrap1a:after {
  content: '';
  background: url(../images/common/color_mark1.png) no-repeat;
  background-size: cover;
  position: absolute;
  right: 1%;
  bottom: -10%;
  width: 400px;
  height: 400px;
  z-index: 0;
}

@media screen and (max-width: 1200px) {
  .bg_img_wrap1a:after {
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 1000px) {
  .bg_img_wrap1a:after {
    width: 300px;
    height: 300px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .bg_img_wrap1a:after {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (max-width: 640px) {
  .bg_img_wrap1a:after {
    width: 180px;
    height: 180px;
    bottom: 0;
  }
}

@media screen and (max-width: 1500px) {
  .bg_img_wrap1a {
    padding: 10% 0 !important;
  }
}

@media screen and (max-width: 1100px) {
  .bg_img_wrap1a {
    padding: 10% 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .bg_img_wrap1a {
    padding: 20% 0 5% 0 !important;
    margin-bottom: 10%;
  }
}

@media only screen and (max-width: 640px) {
  .bg_img_wrap1a {
    padding: 30% 0 5% 0 !important;
    margin-bottom: 10%;
  }
}

.bg_img_wrap1b {
  position: relative;
  padding: 350px 0 !important;
}

@media screen and (max-width: 1000px) {
  .bg_img_wrap1b {
    padding: 250px 0  !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .bg_img_wrap1b {
    padding: 200px 0 5% 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .bg_img_wrap1b {
    padding: 180px 0 5% 0 !important;
  }
}

@media screen and (max-width: 400px) {
  .bg_img_wrap1b {
    padding: 150px 0 5% 0 !important;
  }
}

.bg_img_wrap1b:after {
  position: absolute;
  content: '';
  background: url(../images/common/topmessage.png) no-repeat;
  background-size: contain;
  top: 50px;
  left: 10%;
  width: 500px;
  height: 300px;
}

@media screen and (max-width: 1000px) {
  .bg_img_wrap1b:after {
    width: 400px;
    height: 330px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .bg_img_wrap1b:after {
    width: 300px;
    height: 180px;
  }
}

@media only screen and (max-width: 640px) {
  .bg_img_wrap1b:after {
    width: 200px;
    height: 130px;
  }
}

@media screen and (max-width: 400px) {
  .bg_img_wrap1b:after {
    width: 180px;
    height: 120px;
    left: 5%;
  }
}

.btn_wrap1 {
  max-width: 1560px;
  margin: 0 auto 0 0;
  width: 95%;
  background: url(../images/common/top_bg2.png) no-repeat;
  background-size: cover;
  padding: 7%;
}

@media screen and (max-width: 1200px) {
  .btn_wrap1 {
    padding: 10% 5% 5% 5%;
  }
}

.btn_wrap2 {
  margin: 12% auto 10% auto;
  width: 95%;
}

.btn_cont1 {
  text-align: center;
}

.btn_wrap2_flx {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.btn_wrap2_flx .box_img {
  width: 48%;
  margin: 1%;
}

.btn_wrap2_flx .box_txt {
  width: 48%;
  margin: 1%;
}

@media screen and (max-width: 1300px) {
  .btn_wrap2_flx .box_txt {
    width: 46%;
    margin: 2%;
  }
}

@media only screen and (max-width: 835px) {
  .btn_wrap2_flx {
    flex-direction: column;
  }
  .btn_wrap2_flx .box_img, .btn_wrap2_flx .box_txt {
    width: 100%;
    margin: 0 auto 15px auto;
  }
}

.color_box {
  background-image: -moz-linear-gradient(151deg, #43c6ac 0%, #0168b3 100%);
  background-image: -webkit-linear-gradient(151deg, #43c6ac 0%, #0168b3 100%);
  background-image: -ms-linear-gradient(151deg, #43c6ac 0%, #0168b3 100%);
  width: 100%;
  padding: 9%;
  margin: -2% auto;
  position: relative;
  z-index: -1;
}

.cont_03_flx {
  max-width: 1300px;
  width: 100%;
  margin: 0  auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cont_03_flx .box:first-child {
  width: 38%;
  margin: 1%;
}

.cont_03_flx .box:nth-child(2) {
  width: 58%;
  margin: 1%;
  max-width: 750px;
}

@media screen and (max-width: 1300px) {
  .cont_03_flx .box:first-child {
    width: 28%;
    margin: 1%;
  }
  .cont_03_flx .box:nth-child(2) {
    width: 68%;
    margin: 1%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_03_flx {
    flex-direction: column;
  }
  .cont_03_flx .box:first-child {
    width: 65%;
    margin: 0 auto 0 0;
  }
  .cont_03_flx .box:nth-child(2) {
    width: 95%;
    margin: -15% auto 0 auto;
    padding: 15px;
    background: rgba(255, 255, 255, 0.7);
  }
}

.cont_03_flx .color_box2 {
  position: absolute;
  background-image: -moz-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  background-image: -webkit-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  background-image: -ms-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  opacity: 0.5;
  width: 40%;
  padding: 25% 0;
  margin: 0 auto;
  z-index: -1;
  top: 50%;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1500px) {
  .cont_03_flx .color_box2 {
    right: 10%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_03_flx .color_box2 {
    right: 10%;
    width: 65%;
    padding: 45% 0;
    top: 20%;
  }
}

.cont_03_flxb {
  max-width: 1300px;
  width: 100%;
  margin: 0  auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.cont_03_flxb .box:first-child {
  width: 38%;
  margin: 1%;
}

.cont_03_flxb .box:nth-child(2) {
  width: 58%;
  margin: 1%;
  max-width: 750px;
  min-width: 615px;
}

@media screen and (max-width: 1000px) {
  .cont_03_flxb .box:first-child {
    width: 43%;
    margin: 1%;
  }
  .cont_03_flxb .box:nth-child(2) {
    width: 60%;
    margin: 20% 0 0 -10%;
    min-width: auto;
    background: rgba(255, 255, 255, 0.5);
    padding: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .cont_03_flxb .box:nth-child(2) {
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_03_flxb {
    flex-direction: column;
  }
  .cont_03_flxb .box:first-child {
    width: 65%;
    margin: 0 auto 0 0;
  }
  .cont_03_flxb .box:nth-child(2) {
    width: 90%;
    margin: -15% 0 0 auto;
  }
}

.cont_03_flxb .color_box2 {
  position: absolute;
  background-image: -moz-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  background-image: -webkit-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  background-image: -ms-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  opacity: 0.5;
  width: 40%;
  padding: 25% 0;
  margin: 0 auto;
  z-index: -1;
  top: 50%;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1500px) {
  .cont_03_flxb .color_box2 {
    right: 10%;
  }
}

@media screen and (max-width: 1000px) {
  .cont_03_flxb .color_box2 {
    top: 40%;
  }
}

@media only screen and (max-width: 640px) {
  .cont_03_flxb .color_box2 {
    right: 10%;
    width: 65%;
    padding: 45% 0;
    top: 30%;
  }
}

.cont_03_txt {
  color: #171717;
  font-size: 1.125rem;
  line-height: 2em;
}

@media only screen and (max-width: 1024px) {
  .cont_03_txt {
    font-size: 1rem;
  }
  .cont_03_txt div br {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .cont_03_txt {
    line-height: 1.6em;
  }
}

.top_img1 {
  width: 70%;
  max-width: 950px;
  margin: 0 0 0 auto;
}

.top_img1 img {
  max-width: 100% !important;
}

.bg_img_wrap2 {
  margin: 10% auto 12%;
  position: relative;
  padding: 5% 0 0 0 !important;
}

@media screen and (max-width: 1300px) {
  .bg_img_wrap2 {
    margin: 13% auto 5% auto;
  }
}

@media only screen and (max-width: 835px) {
  .bg_img_wrap2 {
    margin: 10% auto;
  }
}

.bg_img_inr2 {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/color_box1.png), url(../images/common/color_box4.png);
  background-size: 10%, 15%;
  background-repeat: no-repeat;
  background-position: right top, bottom left;
  z-index: -1;
}

@media only screen and (max-width: 640px) {
  .bg_img_inr2 {
    background-size: 20%, 25%;
  }
}

.news_bg {
  position: relative;
  padding: 4% 0  !important;
}

@media screen and (max-width: 1300px) {
  .news_bg {
    padding: 6% 0  !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .news_bg {
    padding: 8% 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .news_bg {
    padding: 10% 0 !important;
  }
}

.news_inr {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/top_bg4.png) no-repeat center;
  background-size: cover;
  z-index: -1;
}

.news-wrap {
  max-width: 1000px;
  width: 95%;
  margin: 20px auto  !important;
  height: 350px;
  padding: 20px 0;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 835px) {
  .news-wrap {
    height: 300px;
  }
}

.bg_color_wrap1 {
  position: relative;
  padding: 3% 0 !important;
}

@media screen and (max-width: 1500px) {
  .bg_color_wrap1 {
    padding: 7% 0 !important;
  }
}

@media only screen and (max-width: 835px) {
  .bg_color_wrap1 {
    padding: 10% 0 !important;
  }
}

.bg_color_inr1 {
  width: 1000%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0% 0% 0% -500%;
  background: rgba(58, 144, 208, 0.15);
  z-index: -1;
}

.cont_04_wrap {
  margin: 12% auto;
  position: relative;
}

.cont_04_wrap .cont_04_inr {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.cont_04_wrap .cont_04_inr .box_img {
  width: 48%;
  margin: 1%;
}

.cont_04_wrap .cont_04_inr .box_txt {
  width: 40%;
  margin: 2%;
  margin-left: 70px;
  max-width: 450px;
}

.cont_04_wrap:before {
  position: absolute;
  content: '';
  background-image: -moz-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  background-image: -webkit-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  background-image: -ms-linear-gradient(151deg, #43c6ac 0%, #b4e1f8 51%, #3c85bc 100%);
  opacity: 0.5;
  width: 100%;
  top: 20%;
  bottom: 20%;
  height: 60%;
  z-index: -1;
}

.cont_04_wrap .box_a {
  position: absolute;
  right: 0;
  top: 0;
}

.cont_04_wrap .box_b {
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 1000px) {
  .cont_04_wrap {
    padding: 50px 0;
  }
  .cont_04_wrap .box_a {
    width: 150px;
  }
  .cont_04_wrap .box_b {
    width: 180px;
  }
  .cont_04_wrap .cont_04_inr .box_txt {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 835px) {
  .cont_04_wrap .cont_04_inr {
    flex-direction: column;
  }
  .cont_04_wrap .cont_04_inr .box_img {
    width: 60%;
    max-width: 100%;
    margin: 0 auto 15px 15px;
  }
  .cont_04_wrap .cont_04_inr .box_img img {
    max-width: 100% !important;
  }
  .cont_04_wrap .cont_04_inr .box_txt {
    width: 360px;
    max-width: 100%;
    margin: 0 0 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .cont_04_wrap {
    padding: 30px 0;
  }
  .cont_04_wrap .box_a {
    width: 100px;
  }
  .cont_04_wrap .box_b {
    width: 150px;
  }
  .cont_04_wrap:before {
    bottom: 0;
    height: 100%;
  }
}

.btn1, .btn1_jp, .btn1_jpb, .btn1_jp2, .btn1_jp_L, .btn1_rad30 {
  width: 90%;
  max-width: 300px;
  padding: 0 5px 5px 0;
  display: inline-block;
  position: relative;
}

.btn1 a, .btn1_jp a, .btn1_jpb a, .btn1_jp2 a, .btn1_jp_L a, .btn1_rad30 a {
  border: 1px solid #fff;
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 1.25rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background: #171717;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btn1 a:after, .btn1_jp a:after, .btn1_jpb a:after, .btn1_jp2 a:after, .btn1_jp_L a:after, .btn1_rad30 a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f067";
  /*アイコンのユニコード*/
  font-size: 0.9375rem;
  font-weight: 500;
  position: absolute;
  /*絶対位置*/
  right: 10px;
  /*アイコンの位置*/
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.btn1 a:hover, .btn1_jp a:hover, .btn1_jpb a:hover, .btn1_jp2 a:hover, .btn1_jp_L a:hover, .btn1_rad30 a:hover {
  opacity: 1;
  left: 5px;
  top: 5px;
  color: #fff;
  background: #3a90d0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border: 1px solid #3a90d0;
}

.btn1 a:hover:after, .btn1_jp a:hover:after, .btn1_jpb a:hover:after, .btn1_jp2 a:hover:after, .btn1_jp_L a:hover:after, .btn1_rad30 a:hover:after {
  transform: translate(5px, 0);
  transition: 0.5s;
  color: #fff;
}

.btn1:after, .btn1_jp:after, .btn1_jpb:after, .btn1_jp2:after, .btn1_jp_L:after, .btn1_rad30:after {
  content: "";
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  background: url(../images/common/btn_ptn1.png) 0 0 repeat;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 640px) {
  .btn1 a, .btn1_jp a, .btn1_jpb a, .btn1_jp2 a, .btn1_jp_L a, .btn1_rad30 a {
    font-size: 1rem;
  }
}

.btn1_jp a, .btn1_jpb a, .btn1_jp2 a, .btn1_jp_L a {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1rem;
}

.btn1_jp a:hover, .btn1_jpb a:hover, .btn1_jp2 a:hover, .btn1_jp_L a:hover {
  border: 1px solid #fff;
  transition: 0.5s;
}

.btn1_jp:after, .btn1_jpb:after, .btn1_jp2:after, .btn1_jp_L:after {
  content: "";
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  background: url(../images/common/btn_ptn2.png) 0 0 repeat;
  position: absolute;
  right: 0;
  bottom: 0;
}

.btn1_jpb:after {
  background: url(../images/common/btn_ptn1.png) 0 0 repeat;
}

.btn1_jp2 {
  display: block;
  margin: 0 auto;
}

.btn1_jp2 a {
  font-family: "Roboto", sans-serif;
}

.btn2 {
  width: 90%;
  max-width: 500px;
  padding: 0 5px 5px 0;
  display: block;
  position: relative;
  margin: 50px auto 0 auto;
}

.btn2 a {
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: #fff;
  background: #171717;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.btn2 a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f067";
  /*アイコンのユニコード*/
  font-size: 0.9375rem;
  font-weight: 500;
  position: absolute;
  /*絶対位置*/
  right: 10px;
  /*アイコンの位置*/
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.btn2 a:hover {
  opacity: 1;
  left: 5px;
  top: 5px;
  color: #fff;
  background: #3a90d0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.btn2 a:hover:after {
  transform: translate(5px, 0);
  transition: 0.5s;
  color: #fff;
}

.btn2:after {
  content: "";
  width: calc(100% - 5px);
  height: calc(100% - 5px);
  background: url(../images/common/btn_ptn1.png) 0 0 repeat;
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 640px) {
  .btn2 a {
    font-size: 1rem;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .btn2 {
    margin: 30px auto 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .btn2 {
    margin: 15px auto 0 auto;
  }
}

.btn1_rad30 a {
  border-radius: 30px;
}

@media screen and (min-width: 640px) {
  .btn1_jp_L a {
    margin: 0 auto 0 0;
  }
}

.bg_color_wrap {
  position: relative;
  padding: 3% 0 !important;
}

@media screen and (max-width: 1500px) {
  .bg_color_wrap {
    padding: 7% 0 !important;
  }
}

@media only screen and (max-width: 835px) {
  .bg_color_wrap {
    padding: 10% 0 !important;
  }
}

.bg_color_inr {
  width: 1000%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0% 0% 0% -500%;
  background: rgba(58, 144, 208, 0.15);
  z-index: -1;
}

.bg_img_wrap {
  position: relative;
  padding: 4% 0  !important;
}

@media screen and (max-width: 1300px) {
  .bg_img_wrap {
    padding: 6% 0  !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .bg_img_wrap {
    padding: 8% 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .bg_img_wrap {
    padding: 10% 0 !important;
  }
}

.bg_img_inr {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/top_bg1.png) repeat top;
  background-size: cover;
  z-index: -1;
}

@media only screen and (max-width: 640px) {
  .bg_img_inr {
    background-position: right bottom;
  }
}

.img_txt_flx {
  display: flex;
  justify-content: space-between;
}

.img_txt_flx .box:first-child {
  width: 38%;
  margin: 1%;
}

.img_txt_flx .box:nth-child(2) {
  width: 58%;
  margin: 1%;
}

@media only screen and (max-width: 640px) {
  .img_txt_flx {
    flex-direction: column;
  }
  .img_txt_flx .box:first-child {
    width: 100%;
    margin: 0 auto;
  }
  .img_txt_flx .box:nth-child(2) {
    width: 100%;
    margin: 0 auto;
  }
}

.txt_img_flx {
  display: flex;
  justify-content: space-between;
}

.txt_img_flx .box:first-child {
  width: 58%;
  margin: 1%;
}

.txt_img_flx .box:nth-child(2) {
  width: 38%;
  margin: 1%;
}

@media only screen and (max-width: 640px) {
  .txt_img_flx {
    flex-direction: column-reverse;
  }
  .txt_img_flx .box:first-child {
    width: 100%;
    margin: 0 auto;
  }
  .txt_img_flx .box:nth-child(2) {
    width: 100%;
    margin: 0 auto;
  }
}

.flx2 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  float: none !important;
}

@media only screen and (max-width: 640px) {
  .flx2 {
    justify-content: space-between;
  }
}

.flx2 .box {
  width: 48%;
  margin: 1%;
}

.flx2 .box img {
  width: 100% !important;
  margin: 0 auto;
}

.flx2 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

@media screen and (max-width: 350px) {
  .flx2 {
    flex-direction: column;
  }
  .flx2 .box {
    width: 100%;
    margin: 0 auto 15px auto;
  }
}

.flx2b {
  margin: 0 0 0 auto;
  max-width: 800px;
  position: relative;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
}

.flx2b .box {
  width: 46%;
  margin: 2%;
}

.flx2b .box article > h3 {
  display: none;
}

.flx2b .box img {
  width: 100% !important;
}

.flx2b .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flx2b .box .btn1_jpb {
  width: 100%;
  max-width: 350px;
}

@media screen and (max-width: 1300px) {
  .flx2b {
    flex-direction: column;
  }
  .flx2b .box {
    width: 100%;
    margin: 0 auto;
  }
  .flx2b .box:not(:last-child) {
    margin-bottom: 15px;
  }
  .flx2b .box .btn1_jpb {
    margin: 0  auto;
    display: block;
  }
}

.flx3 {
  max-width: 1200px;
  position: relative;
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

@media only screen and (max-width: 835px) {
  .flx3 {
    justify-content: space-between;
  }
}

.flx3 .box {
  width: 32%;
  margin: 0.5%;
}

.flx3 .box article > h3 {
  display: none;
}

.flx3 .box img {
  width: 100% !important;
}

.flx3 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flx3 .box .btn1_jp, .flx3 .box .btn1_jpb, .flx3 .box .btn1_jp2, .flx3 .box .btn1_jp_L {
  width: 100%;
  max-width: 390px;
}

@media only screen and (max-width: 640px) {
  .flx3 {
    flex-direction: column;
  }
  .flx3 .box {
    width: 100%;
    margin: 0 auto;
  }
  .flx3 .box:not(:last-child) {
    margin-bottom: 15px;
  }
}

.flx3b {
  margin: 0 0 0 auto;
  max-width: 1200px;
  position: relative;
  border: 0;
  display: flex;
  flex-wrap: nowrap !important;
}

.flx3b .box {
  width: 350px;
  margin: 2%;
}

.flx3b .box article > h3 {
  display: none;
}

.flx3b .box img {
  width: 100% !important;
}

.flx3b .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flx3b .box .btn1_jp, .flx3b .box .btn1_jpb, .flx3b .box .btn1_jp2, .flx3b .box .btn1_jp_L {
  width: 100%;
  max-width: 350px !important;
}

@media only screen and (max-width: 835px) {
  .flx3b {
    flex-direction: column !important;
  }
  .flx3b .box {
    width: 100%;
    margin: 0 auto;
  }
  .flx3b .box:not(:last-child) {
    margin-bottom: 15px;
  }
}

.flx4 {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.flx4 .box {
  width: 23%;
  margin: 1%;
}

.flx4 .box article > h3 {
  display: none;
}

.flx4 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flx4 .box img {
  width: 100% !important;
}

@media only screen and (max-width: 835px) {
  .flx4 .box {
    width: 48%;
  }
}

@media screen and (max-width: 350px) {
  .flx4 {
    flex-direction: column;
  }
  .flx4 .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .flx4 .box:not(:last-child) {
    margin-bottom: 15px;
  }
}

.flx5 {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.flx5 .box {
  width: 18%;
  margin: 1%;
}

.flx5 .box article a:hover img {
  opacity: 0.6 !important;
}

.flx5 .box article > h3 {
  display: none;
}

.flx5 .box article div {
  text-align: center;
}

.flx5 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flx5 .box img {
  display: block;
  margin: 0 auto;
}

@media only screen and (max-width: 835px) {
  .flx5 .box {
    width: 48%;
  }
}

@media screen and (max-width: 350px) {
  .flx5 {
    flex-direction: column;
  }
  .flx5 .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .flx5 .box:not(:last-child) {
    margin-bottom: 15px;
  }
}

.top-insta .sns_list {
  flex-direction: inherit !important;
  border: none  !important;
  flex-wrap: wrap;
}

.top-insta .sns_list .sns_text {
  display: none;
}

.top-insta .sns_list .sns_photo {
  width: 100% !important;
  height: 100%;
  padding: 0 !important;
}

.top-insta .sns_list .sns_photo img {
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

.top-insta .sns_list > div {
  border: none !important;
  width: 16%;
  margin: 0.333%;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .top-insta .sns_list > div {
    width: 49%;
  }
}

@media only screen and (max-width: 640px) {
  .top-insta .sns_list > div {
    width: 49%;
  }
}

@media screen and (max-width: 350px) {
  .top-insta .sns_list > div {
    width: 99%;
  }
}

section div.form_wrap dl dt {
  background: transration !important;
}

.bssp {
  margin: 20px auto;
}

@media only screen and (max-width: 640px) {
  .bssp {
    margin: 15px auto;
  }
}

a.tel-link {
  color: #171717;
  opacity: 1;
}

@media only screen and (max-width: 835px) {
  .brdnt {
    text-align: left !important;
  }
  .brdnt br {
    display: none;
  }
  .brdnt div {
    text-align: left !important;
  }
  .brdnt div br {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .brdn {
    text-align: left !important;
  }
  .brdn br {
    display: none;
  }
  .brdn div {
    text-align: left !important;
  }
  .brdn div br {
    display: none;
  }
}

@media screen and (min-width: 836px) {
  .brdnpc br {
    display: none;
  }
}

@media screen and (min-width: 641px) {
  .brdnpct br {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .CLS table tr {
    display: flex;
    flex-direction: column-reverse;
  }
}

.company_DL dt {
  align-items: center !important;
  background: rgba(58, 144, 208, 0.7) !important;
  color: #fff;
  min-width: 250px;
}

@media only screen and (max-width: 640px) {
  .company_DL dl {
    display: block !important;
  }
  .company_DL dl dt {
    width: 100% !important;
    border-right: 1px solid #ccc !important;
  }
  .company_DL dl dd {
    width: 100% !important;
  }
}

.company_DL2 dl dt {
  border-bottom: 1px solid #171717 !important;
  background: none  !important;
  padding: 2% !important;
}

.company_DL2 dl dd {
  border-bottom: 1px solid #ccc !important;
  padding: 2% 2% 2% 5% !important;
}

@media only screen and (max-width: 640px) {
  .company_DL2 dl {
    display: block !important;
  }
  .company_DL2 dl dt {
    border-bottom: none !important;
    width: 100% !important;
    background: rgba(58, 144, 208, 0.7) !important;
    color: #fff;
    align-items: center  !important;
  }
  .company_DL2 dl dd {
    width: 100% !important;
    border: 1px solid #ccc !important;
  }
}

.form_wrap dl dd > div {
  display: block !important;
}

@media only screen and (max-width: 835px) {
  .fancybox-toolbar {
    top: 50px !important;
  }
}

.pankuzu {
  max-width: 1600px;
  text-align: right;
  margin-top: 20px;
}

@media only screen and (max-width: 835px) {
  .pankuzu {
    display: none;
  }
}

.imgL {
  max-width: 100% !important;
  margin: 0 auto !important;
}

.error2 h2 {
  font-size: 40px;
  font-weight: bold;
}

.error2 a {
  border: 1px solid #ccc;
  padding: 3px 20px;
  display: inline-block;
}

.pick01 {
  max-width: 1200px;
}

.pick01 h2, .pick01 h3 {
  font-size: 1.875rem;
  color: #171717;
  font-weight: 600;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 15px;
  text-align: center !important;
  line-height: 1.5em;
}

@media (max-width: 1200px) {
  .pick01 h2, .pick01 h3 {
    font-size: calc(1.3125rem + 0.75vw) ;
  }
}

.pick01 h2:after, .pick01 h3:after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #333;
  border-image: linear-gradient(to right, #3a90d0 0%, #43c6ac);
  border-image-slice: 1;
  width: 50%;
  height: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0;
}

@media only screen and (max-width: 835px) {
  .pick01 h2, .pick01 h3 {
    font-size: 1.125rem;
  }
  .pick01 h2 br, .pick01 h3 br {
    display: block;
  }
}

.pick01 h4 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3a90d0;
}

@media only screen and (max-width: 835px) {
  .pick01 h4 {
    font-size: 1rem;
  }
}

.pick02 {
  background: rgba(248, 229, 22, 0.1);
  padding: 15px;
}

@media only screen and (max-width: 835px) {
  .pick02 {
    padding: 10px;
  }
}

.pick02 h4 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #3a90d0;
}

@media only screen and (max-width: 835px) {
  .pick02 h4 {
    font-size: 1rem;
  }
}

.hosoku {
  background: #ededed;
  padding: 15px;
  font-size: 0.8125rem;
}

.hosoku h2, .hosoku h3 {
  font-size: 1rem;
  color: #171717;
  font-weight: 600;
}

.contact_contents #contents_wrap {
  margin-top: 100px;
}

@media only screen and (max-width: 835px) {
  .contact_contents #contents_wrap {
    margin-top: 50px;
  }
}

.img100 img {
  display: block !important;
  width: 100% !important;
}

section form h2.mail.title {
  display: none;
}

.page_title_wrap {
  position: relative;
  padding: 10% 0 !important;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .page_title_wrap {
    padding: 8% 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .page_title_wrap {
    padding: 10% 0 !important;
  }
}

.page_title_inr {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/page_title_bg.png) no-repeat top;
  z-index: -1;
  background-size: cover;
}

.page_title_wrap2 {
  position: relative;
  padding: 7% 0 !important;
}

@media screen and (max-width: 1500px) {
  .page_title_wrap2 {
    padding: 8% 0 !important;
  }
}

@media screen and (max-width: 1200px) {
  .page_title_wrap2 {
    padding: 10% 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .page_title_wrap2 {
    padding: 8% 0 !important;
  }
}

@media only screen and (max-width: 640px) {
  .page_title_wrap2 {
    padding: 10% 0 !important;
  }
}

.step-title h2, .step-title2 h2 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #171717;
  position: relative;
  border-top: solid 2px rgba(58, 144, 208, 0.3);
  border-bottom: solid 2px rgba(58, 144, 208, 0.3);
  background: rgba(58, 144, 208, 0.1);
  line-height: 1.4;
  padding: 0.2em;
}

@media (max-width: 1200px) {
  .step-title h2, .step-title2 h2 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.step-title h2:after, .step-title2 h2:after {
  /*タブ*/
  position: absolute;
  font-family: "Font Awesome 5 pro", "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  content: '\f0a6\ Point';
  font-size: 0.875rem;
  background: #3a90d0;
  color: #fff;
  left: 0px;
  bottom: 100%;
  border-radius: 3px 3px 0 0;
  padding: 5px 10px 3px;
  line-height: 1;
  letter-spacing: 0.05em;
}

@media only screen and (max-width: 835px) {
  .step-title h2 h2, .step-title2 h2 h2 {
    font-size: 1.0625rem;
  }
}

.step-title h3, .step-title2 h3 {
  font-size: 1.5rem;
  background: #eee;
  color: #3a90d0;
  position: relative;
  border-top: solid 2px #f8e516;
  border-bottom: solid 2px #f8e516;
  line-height: 1.4;
  padding: 0.2em;
}

@media (max-width: 1200px) {
  .step-title h3, .step-title2 h3 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.step-title h3:after, .step-title2 h3:after {
  /*タブ*/
  position: absolute;
  font-family: "Font Awesome 5 pro" , Montserrat ,sans-serif;
  content: '\f5ad\ point';
  background: #f8e516;
  color: #fff;
  left: 0px;
  bottom: 100%;
  border-radius: 3px 3px 0 0;
  padding: 5px 10px 3px;
  font-size: 0.7em;
  line-height: 1;
  letter-spacing: 0.05em;
}

.step-title2 h3:after {
  content: '\f4b6\ voice';
}

.step-title3 h2 {
  font-size: 1.5rem;
  color: #f8e516;
  line-height: 1.5em;
  border-bottom: 1px solid #3a90d0;
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .step-title3 h2 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.step-title3 h2 span {
  background: #3a90d0;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  margin-right: 5px;
}

.staff-title h2, .staff-title h3 {
  font-size: 1.25rem;
  color: #171717;
  position: relative;
  border-top: solid 2px #3a90d0;
  border-bottom: solid 2px #3a90d0;
  background: rgba(58, 144, 208, 0.1);
  line-height: 1.4;
  padding: 0.2em;
}

.staff-title h2:after, .staff-title h3:after {
  /*タブ*/
  position: absolute;
  font-family: "Font Awesome 5 pro", 'Century Gothic';
  font-weight: 900;
  content: '\f007\ TEACHER';
  background: #3a90d0;
  color: #fff;
  left: 0px;
  bottom: 100%;
  border-radius: 3px 3px 0 0;
  padding: 5px 10px 3px;
  font-size: 0.7em;
  line-height: 1;
  letter-spacing: 0.05em;
}

@media only screen and (max-width: 835px) {
  .staff-title h2, .staff-title h3 {
    font-size: 1.125rem;
  }
}

.step-title3 h3 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #171717;
  border-bottom: 1px dashed #555;
  padding-bottom: 5px;
}

.step-title3 h3 span {
  font-size: 1.25rem;
  background: #3a90d0;
  border-radius: 3px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  font-weight: 500;
  padding: 5px 5px 3px 5px;
  margin-right: 5px;
}

.plan_flx {
  align-items: flex-end;
}

.plan_flx .box table td {
  padding: 5px !important;
}

@media screen and (max-width: 1200px) {
  .plan_flx {
    align-items: center;
  }
  .plan_flx .box:first-child {
    width: 50%;
  }
  .plan_flx .box:nth-child(2) {
    width: 50%;
    margin-top: 80px;
  }
}

@media screen and (max-width: 1000px) {
  .plan_flx .title_a h2 {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 835px) {
  .plan_flx {
    flex-direction: column;
  }
  .plan_flx .box:first-child, .plan_flx .box:nth-child(2) {
    width: 100%;
  }
}

.arrow {
  text-align: center;
}

.arrow i {
  font-size: 3.125rem;
  color: #555;
}

@media (max-width: 1200px) {
  .arrow i {
    font-size: calc(1.4375rem + 2.25vw) ;
  }
}

@media only screen and (max-width: 835px) {
  .arrow i {
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 835px) and (max-width: 1200px) {
  .arrow i {
    font-size: calc(1.3125rem + 0.75vw) ;
  }
}

.policy h2 {
  font-size: 1.25rem;
}

.policy h3 {
  color: #171717;
  font-weight: 500;
  position: relative;
  padding-left: 20px;
  margin: 20px auto auto auto;
}

.policy h3:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '■';
  width: 16px;
  height: 16px;
}

.mission_wrap, .mission_wrapb {
  margin: 12% auto;
  padding: 15% 15% 10% 15%;
  position: relative;
  background-position: bottom;
}

.mission_wrap:before, .mission_wrapb:before {
  position: absolute;
  content: '';
  background-image: -moz-linear-gradient(151deg, #0168b3 0%, #43c6ac 100%);
  background-image: -webkit-linear-gradient(151deg, #0168b3 0%, #43c6ac 100%);
  background-image: -ms-linear-gradient(151deg, #0168b3 0%, #43c6ac 100%);
  width: 100%;
  height: 100%;
  z-index: -1;
  top: -10%;
  left: -10%;
}

@media screen and (max-width: 1000px) {
  .mission_wrap, .mission_wrapb {
    padding: 15% 10% 10% 10%;
  }
}

@media only screen and (max-width: 640px) {
  .mission_wrap, .mission_wrapb {
    margin: 15% auto;
  }
}

.mission_wrapb {
  background-position: center;
}

.mission_wrapb:before {
  left: auto;
  right: -10%;
}

.mission_flx {
  display: flex;
  justify-content: space-between;
}

.mission_flx .box:first-child {
  width: 50%;
  margin: 0 -5% -5% 0;
}

.mission_flx .box:first-child img {
  width: 100%;
}

.mission_flx .box:nth-child(2) {
  width: 60%;
  margin: -5% 0 0 auto;
  background: rgba(58, 144, 208, 0.8);
  padding: 15px;
  color: #fff;
  font-size: 1rem;
  line-height: 2.3em;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .mission_flx .box:nth-child(2) {
    line-height: 1.5em;
  }
}

@media only screen and (max-width: 640px) {
  .mission_flx {
    flex-direction: column;
  }
  .mission_flx .box:first-child {
    width: 100%;
    margin: 0 auto;
  }
  .mission_flx .box:nth-child(2) {
    width: 100%;
    margin: 0 auto;
    line-height: 1.5em;
  }
  .mission_flx .box:nth-child(2) br {
    display: none;
  }
}

.mission_flxb {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10%;
}

.mission_flxb .box:first-child {
  width: 50%;
  margin: 0 0 -5% auto;
}

.mission_flxb .box:first-child img {
  width: 100%;
}

.mission_flxb .box:nth-child(2) {
  width: 55%;
  margin: -3% auto -5% 0;
  background: rgba(1, 21, 107, 0.8);
  padding: 15px;
  color: #fff;
  font-size: 1rem;
  line-height: 2.3em;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .mission_flxb .box:nth-child(2) {
    line-height: 1.5em;
  }
}

@media only screen and (max-width: 640px) {
  .mission_flxb {
    flex-direction: column;
  }
  .mission_flxb .box:first-child {
    width: 100%;
    margin: 0 auto;
  }
  .mission_flxb .box:nth-child(2) {
    width: 100%;
    margin: 0 auto;
    line-height: 1.5em;
  }
  .mission_flxb .box:nth-child(2) br {
    display: none;
  }
}

@media screen and (min-width: 835px) {
  .business_flx {
    background: #f2f2f2;
  }
}

@media only screen and (max-width: 835px) {
  .business_flx .box article img {
    background: #f2f2f2;
  }
}

.business_flx .box article div {
  text-align: left;
}

.business_flx .box article div h2 {
  background: #3a90d0;
  color: #fff;
  font-size: 1.125rem;
  text-align: center;
  padding: 5px;
}

@media only screen and (max-width: 640px) {
  .blog_flx {
    flex-direction: row;
  }
  .blog_flx .box {
    width: 48%;
    margin: 1%;
  }
}

@media screen and (max-width: 350px) {
  .blog_flx {
    flex-direction: xolumn;
  }
  .blog_flx .box {
    margin: 0 auto 15px auto;
  }
}

.ceo_txt_ttl {
  color: #171717;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", "a-otf-ryumin-pr6n", serif;
  font-size: 1.5rem;
  line-height: 1.7em;
}

@media (max-width: 1200px) {
  .ceo_txt_ttl {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.ceo_txt_ttl div {
  font-weight: bold;
  border-bottom: 2px solid #333;
  border-image: linear-gradient(to right, #3a90d0 0%, #43c6ac);
  border-image-slice: 1;
}

@media screen and (max-width: 1000px) {
  .ceo_txt_ttl {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 835px) {
  .ceo_txt_ttl {
    font-size: 1.125rem;
    line-height: 1.6em;
  }
}

.ceo_txt div {
  color: #171717;
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
}

@media screen and (max-width: 1000px) {
  .ceo_txt div {
    font-weight: 500;
    line-height: 1.7em;
  }
  .ceo_txt div br {
    display: none;
  }
}

.ceo_txt div .nameR {
  text-align: right;
  font-weight: bold;
  font-size: 1.1em;
  margin-top: 15px;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", "a-otf-ryumin-pr6n", serif;
}

@media only screen and (max-width: 835px) {
  .ceo_txt div .nameR {
    font-size: 1.1em;
  }
}

.staff_cont_01, .staff_cont_02, .staff_cont_03, .staff_cont_04, .staff_cont_05, .staff_cont_06 {
  position: relative;
  background: #81cbf2;
  padding: 3% 0 0 0;
}

.staff_cont_01 .staff_coment, .staff_cont_02 .staff_coment, .staff_cont_03 .staff_coment, .staff_cont_04 .staff_coment, .staff_cont_05 .staff_coment, .staff_cont_06 .staff_coment {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.staff_cont_01 .staff_coment img, .staff_cont_02 .staff_coment img, .staff_cont_03 .staff_coment img, .staff_cont_04 .staff_coment img, .staff_cont_05 .staff_coment img, .staff_cont_06 .staff_coment img {
  margin: 0 auto;
  width: 35% !important;
}

@media only screen and (max-width: 835px) {
  .staff_cont_01 .staff_coment img, .staff_cont_02 .staff_coment img, .staff_cont_03 .staff_coment img, .staff_cont_04 .staff_coment img, .staff_cont_05 .staff_coment img, .staff_cont_06 .staff_coment img {
    width: 80% !important;
  }
}

.staff_cont_01 .scroll_button, .staff_cont_02 .scroll_button, .staff_cont_03 .scroll_button, .staff_cont_04 .scroll_button, .staff_cont_05 .scroll_button, .staff_cont_06 .scroll_button {
  position: absolute;
  width: 90%;
  min-width: 300px;
  max-width: 500px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  background: #000;
}

.staff_cont_01 .scroll_button div a, .staff_cont_02 .scroll_button div a, .staff_cont_03 .scroll_button div a, .staff_cont_04 .scroll_button div a, .staff_cont_05 .scroll_button div a, .staff_cont_06 .scroll_button div a {
  padding: 20px 10px;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.staff_cont_01 .scroll_button div a:after, .staff_cont_02 .scroll_button div a:after, .staff_cont_03 .scroll_button div a:after, .staff_cont_04 .scroll_button div a:after, .staff_cont_05 .scroll_button div a:after, .staff_cont_06 .scroll_button div a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f067";
  /*アイコンのユニコード*/
  font-size: 0.9375rem;
  font-weight: 500;
  color: #fff;
  position: absolute;
  /*絶対位置*/
  right: 10px;
  /*アイコンの位置*/
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.staff_cont_01 .scroll_button div a:hover:after, .staff_cont_02 .scroll_button div a:hover:after, .staff_cont_03 .scroll_button div a:hover:after, .staff_cont_04 .scroll_button div a:hover:after, .staff_cont_05 .scroll_button div a:hover:after, .staff_cont_06 .scroll_button div a:hover:after {
  transform: translate(5px, 0);
  transition: 0.5s;
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .staff_cont_01 .scroll_button, .staff_cont_02 .scroll_button, .staff_cont_03 .scroll_button, .staff_cont_04 .scroll_button, .staff_cont_05 .scroll_button, .staff_cont_06 .scroll_button {
    bottom: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .staff_cont_01, .staff_cont_02, .staff_cont_03, .staff_cont_04, .staff_cont_05, .staff_cont_06 {
    height: 100vh !important;
  }
  .staff_cont_01 .staff_main_img img, .staff_cont_02 .staff_main_img img, .staff_cont_03 .staff_main_img img, .staff_cont_04 .staff_main_img img, .staff_cont_05 .staff_main_img img, .staff_cont_06 .staff_main_img img {
    height: 98vh !important;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.staff_cont_02 {
  background: #85dee4;
}

.staff_cont_03 {
  background: #35adec;
}

.staff_cont_04 {
  background: #f6ee20;
}

.staff_cont_05 {
  background: #0168b3;
}

.staff_cont_06 {
  background: #4bc7b4;
}

.staff_flx1 {
  max-width: 1500px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.staff_flx1 .box_txt {
  width: 90%;
  margin: 10% auto 5% -10%;
  min-width: 300px;
  max-width: auto;
  background: rgba(0, 0, 0, 0.8);
  padding: 1%;
  color: #fff;
  display: flex;
}

.staff_flx1 .box_txt > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.staff_flx1 .box_txt .staff_prof {
  padding: 5%;
  position: relative;
}

.staff_flx1 .box_txt .staff_prof h1 {
  font-size: 1.25rem;
}

.staff_flx1 .box_txt .staff_prof .en {
  display: block;
  color: #3a90d0;
  font-family: "Roboto", sans-serif;
}

.staff_flx1 .box_txt .staff_prof:after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 1px;
  left: 5%;
  bottom: 0;
}

.staff_flx1 .box_txt .staff_career {
  padding: 5%;
}

.staff_flx1 .box_img {
  width: 90%;
  max-width: 960px;
  min-width: 700px;
  margin: 1%;
  position: relative;
  z-index: -1;
}

.staff_flx1 .box_img img {
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .staff_flx1 .box_txt {
    margin: 10% auto 5% -30%;
  }
  .staff_flx1 .box_img {
    min-width: 600px;
  }
}

@media only screen and (max-width: 835px) {
  .staff_flx1 {
    flex-direction: column;
  }
  .staff_flx1 .box_img {
    min-width: auto;
  }
  .staff_flx1 .box_txt {
    margin: -10% 0 0 auto;
  }
}

@media only screen and (max-width: 835px) {
  .staff_flx1 {
    flex-direction: column;
  }
  .staff_flx1 .box_img {
    min-width: auto;
  }
  .staff_flx1 .box_txt {
    margin: -10% 0 0 auto;
  }
}

.staff_flx2, .staff_flx2b {
  max-width: 1300px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.staff_flx2 .box_txt, .staff_flx2b .box_txt {
  width: 58%;
  margin: 1%;
}

.staff_flx2 .box_img, .staff_flx2b .box_img {
  width: 38%;
  margin: 1%;
}

@media only screen and (max-width: 640px) {
  .staff_flx2, .staff_flx2b {
    width: 90%;
    flex-direction: column-reverse;
  }
  .staff_flx2 .box_img, .staff_flx2b .box_img {
    width: 100%;
    margin: 0 auto 15px auto;
  }
  .staff_flx2 .box_txt, .staff_flx2b .box_txt {
    width: 100%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 640px) {
  .staff_flx2b {
    flex-direction: column;
  }
}

.others_flx {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.others_flx .box {
  width: 20%;
  position: relative;
  padding-top: 10px;
}

.others_flx .box article > h3 {
  display: none;
}

.others_flx .box article img {
  display: block;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100% !important;
}

.others_flx .box article .prof {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 200px;
  width: 90%;
}

.others_flx .box article .prof .group {
  background: #171717;
  color: #fff;
  padding: 3px 10px;
  display: block;
  width: 200px;
  text-align: center;
  font-size: 0.8125rem;
}

.others_flx .box article .prof h3 {
  background: #fefefe;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #171717;
  line-height: 1.7em;
}

.others_flx .box article .prof h3 .year {
  font-size: 0.8125rem;
}

.others_flx .box article .prof h3 div {
  font-size: 1.25rem;
  font-weight: 500;
}

.others_flx .box article .prof h3 div .en {
  display: block;
  color: #3a90d0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  margin-top: 5px;
}

.others_flx .box article .yarigai {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(58, 144, 208, 0.8);
  display: none;
}

.others_flx .box article .yarigai p {
  color: #fff;
  font-size: 1.2em;
  font-weight: 500;
  padding: 5px;
}

.others_flx .box:hover .yarigai {
  display: flex;
  transition: 0.5s;
}

.others_flx .box:first-child {
  background: #81cbf2;
}

.others_flx .box:nth-child(2) {
  background: #4bc7b4;
}

.others_flx .box:nth-child(3) {
  background: #35adec;
}

.others_flx .box:nth-child(4) {
  background: #85dee4;
}

.others_flx .box:nth-child(5) {
  background: #f6ee20;
}

.others_flx .box:nth-child(6) {
  background: #0168b3;
}

.others_flx .box:nth-child(7) {
  background: #85dee4;
}

.others_flx .box:nth-child(8) {
  background: #81cbf2;
}

.others_flx .box:nth-child(9) {
  background: #4bc7b4;
}

.others_flx .box:nth-child(10) {
  background: #35adec;
}

.others_flx .box:nth-child(11) {
  background: #85dee4;
}

.others_flx .box:nth-child(12) {
  background: #f6ee20;
}

.others_flx .box:nth-child(13) {
  background: #35adec;
}

.others_flx .box:nth-child(14) {
  background: #85dee4;
}

.others_flx .box:nth-child(15) {
  background: #0168b3;
}

@media screen and (max-width: 1500px) {
  .others_flx .box {
    width: 25%;
  }
  .others_flx .box article .prof h3 div {
    font-size: 1.125rem;
  }
  .others_flx .box article .prof h3 div .en {
    font-size: 0.8125rem;
    margin-top: 0;
  }
  .others_flx .box article .yarigai p {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 1200px) {
  .others_flx .box article .yarigai p {
    font-size: 1.0em;
    line-height: 1.5em;
  }
}

@media screen and (max-width: 1050px) {
  .others_flx .box {
    width: 33.333%;
  }
  .others_flx .box article .prof h3 div {
    font-size: 1.125rem;
  }
  .others_flx .box article .prof h3 div .en {
    font-size: 0.8125rem;
    margin-top: 0;
  }
  .others_flx .box article .yarigai p {
    font-size: 1.1em;
    line-height: 1.7em;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .others_flx .box {
    width: 50%;
    border: 1px solid #fff;
  }
  .others_flx .box .yarigai {
    display: flex !important;
    background: none !important;
    text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
  }
  .others_flx .box .yarigai p {
    font-size: 1.2em;
    line-height: 2em;
  }
}

@media only screen and (max-width: 640px) {
  .others_flx {
    width: 100vw !important;
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .others_flx .box {
    width: 100%;
  }
  .others_flx .box .yarigai {
    display: flex !important;
    background: none !important;
    text-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
  }
  .others_flx .box .yarigai p {
    font-size: 1.2em;
    line-height: 2em;
  }
}

#TRANS_P3C2H {
  display: block !important;
}

.rs-tel {
  font-size: 1.5rem;
  color: #171717 !important;
  font-family: century-gothic, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .rs-tel {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.rs-tel a {
  color: #171717;
}

.rs-tel:before {
  /*
  font-family: "Font Awesome 5 Free";
  content: "\f0c4";
  font-weight: 600;
 */
  content: '';
  background: url(../images/common/header_tel_icon.png) no-repeat;
  background-size: contain;
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  padding-right: 5px;
}

.list01 dl, .list02 dl {
  border-bottom: 1px dashed #171717;
}

.list01 dl dt, .list02 dl dt {
  border: none !important;
  background: none !important;
}

.list01 dl dd, .list02 dl dd {
  border: none !important;
  background: none !important;
  text-align: right !important;
  min-width: 180px;
  color: #3a90d0;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .list01 dl, .list02 dl {
    display: block !important;
  }
  .list01 dl dd, .list02 dl dd, .list01 dl dt, .list02 dl dt {
    width: 100% !important;
  }
  .list01 dl dd, .list02 dl dd {
    padding: 0 !important;
  }
}

.list02 dl dd {
  min-width: 300px;
}

@media only screen and (max-width: 640px) {
  .list02 dl {
    display: block !important;
  }
  .list02 dl dd, .list02 dl dt {
    width: 100% !important;
  }
}

.list03 dl {
  border-bottom: 1px dashed #171717;
}

.list03 dl dt {
  border: none !important;
  background: none !important;
  color: #8a4609;
  font-weight: bold;
  min-width: 280px;
}

.list03 dl dd {
  border: none !important;
  background: none !important;
  text-align: right !important;
}

@media only screen and (max-width: 640px) {
  .list03 dl {
    display: block !important;
  }
  .list03 dl dd, .list03 dl dt {
    width: 100% !important;
    text-align: left !important;
  }
}

.faq table td {
  border: none;
}

.faq-q span, .faq-a span {
  width: 40px;
  height: 40px;
  display: block;
  background: #171717;
  line-height: 40px;
  border-radius: 5px;
  color: #fff;
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .faq-q span, .faq-a span {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

@media only screen and (max-width: 640px) {
  .faq-q, .faq-a {
    background: #171717;
    padding: 0 !important;
  }
  .faq-q span, .faq-a span {
    display: inline-block;
  }
}

.faq-a span {
  background: #3a90d0;
}

@media only screen and (max-width: 640px) {
  .faq-a {
    background: #3a90d0;
  }
}

@media screen and (min-width: 640px) {
  .faq-q-txt {
    border-bottom: 1px dashed #ccc !important;
  }
}

.qa_FLX .box:first-child {
  margin-bottom: 10px;
}

.qa_FLX .box:first-child > div {
  display: flex;
}

.qa_FLX .box:first-child > div .icon {
  background: #3a90d0;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  line-height: 2em;
  margin-right: 10px;
  color: #fff;
  display: block;
  font-size: 1.25rem;
  text-align: center;
}

.qa_FLX .box:first-child > div .txt {
  width: 100%;
}

.qa_FLX .box:nth-child(2) > div {
  display: flex;
}

.qa_FLX .box:nth-child(2) > div .icon {
  background: #f8e516;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  line-height: 2em;
  margin-right: 10px;
  color: #fff;
  display: block;
  font-size: 1.25rem;
  text-align: center;
}

.qa_FLX .box:nth-child(2) > div .txt {
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .qa_FLX .box:first-child > div, .qa_FLX .box:nth-child(2) > div {
    flex-direction: column;
  }
  .qa_FLX .box:first-child > div .icon, .qa_FLX .box:nth-child(2) > div .icon {
    width: 100%;
  }
}

.qa_FLX:not(:last-child) {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}

.blog {
  max-width: 1200px;
  margin: 80px auto 0;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .blog {
    margin: 50px auto 0;
  }
}

@media only screen and (max-width: 640px) {
  .blog {
    margin: 30px auto 0;
  }
}

.blog .wrap {
  display: flex;
}

.blog .wrap aside {
  width: 28%;
  margin: 1%;
}

.blog .wrap aside nav {
  line-height: 2.0em;
}

.blog .wrap aside nav h3 {
  background: #171717 !important;
}

.blog .wrap aside nav ul li .blogside {
  color: #171717;
}

.blog .wrap aside nav ul li .blogside span {
  color: #3a90d0;
  margin-left: 5px;
  display: block;
}

.blog .wrap aside nav > a {
  background: #f8e516 !important;
}

.blog .wrap #contents {
  width: 68%;
  margin: 1%;
  min-width: auto;
}

.blog .wrap #contents article .date {
  color: #3a90d0 !important;
}

.blog .wrap #contents article div img {
  width: 48%;
  margin: 1%;
}

.blog .wrap #contents article .date {
  color: #3a90d0 !important;
}

.blog .wrap #contents article h1, .blog .wrap #contents article h2 {
  font-size: 1.125rem;
  padding: 0.4em 0.5em;
  /*文字の上下 左右の余白*/
  margin-bottom: 20px;
  background: #3a90d0;
  color: #fff !important;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 835px) {
  .blog .wrap {
    flex-direction: column;
  }
  .blog .wrap aside, .blog .wrap #contents {
    width: 100%;
    margin: 10px auto;
  }
  .blog .wrap aside {
    text-align: center !important;
  }
}

@media only screen and (max-width: 640px) {
  .blog .wrap #contents article {
    padding: 0;
  }
  .blog .wrap #contents article div {
    flex-direction: column;
  }
  .blog .wrap #contents article div img {
    width: 100%;
  }
}

.top_blog {
  height: 100%;
  overflow-y: auto;
  line-height: 1.5;
}

.top_blog .blog_list {
  border: none;
}

.top_blog .blog_list .blog_date {
  background: #3a90d0;
  font-size: 0.9375rem;
  font-weight: 500;
  color: #fff;
  font-family: "Roboto", sans-serif;
  padding: 0 5px;
  display: inline-block;
}

.top_blog .blog_list h3 {
  margin-bottom: 0;
}

.top_blog .blog_list h3 a {
  color: #171717;
}

.top_blog .blog_list .blog_text .detail {
  margin-top: 0;
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .top_blog .blog_list > div {
    flex-direction: row !important;
  }
  .top_blog .blog_photo {
    width: 30%  !important;
  }
}

@media only screen and (max-width: 640px) {
  .top_blog .blog_photo {
    display: none;
  }
}

.top_blog2 {
  max-width: 1200px;
  margin: auto;
  width: 95%;
}

.top_blog2 .blog_list {
  flex-direction: row;
  flex-wrap: wrap;
  border: none;
}

.top_blog2 .blog_list > div {
  width: 31.333%;
  margin: 1%;
  display: block;
  border: none;
}

.top_blog2 .blog_list > div .blog_photo {
  width: 100%;
  padding: 0;
  border: 1px solid #ccc;
  background: #fff;
}

.top_blog2 .blog_list > div .blog_photo img {
  height: 220px !important;
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media screen and (max-width: 1200px) {
  .top_blog2 .blog_list > div .blog_photo img {
    height: 180px !important;
  }
}

@media screen and (max-width: 1000px) {
  .top_blog2 .blog_list > div .blog_photo img {
    height: 150px !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .top_blog2 .blog_list > div .blog_photo img {
    height: 130px !important;
  }
}

@media only screen and (max-width: 640px) {
  .top_blog2 .blog_list > div .blog_photo img {
    height: 100px !important;
  }
}

.top_blog2 .blog_list > div .blog_text {
  width: 100%;
  padding: 0;
}

.top_blog2 .blog_list > div .blog_text .blog_date {
  color: #333;
  font-size: 0.9375rem;
  font-family: "Roboto", sans-serif;
}

.top_blog2 .blog_list > div .blog_text h3 a {
  color: #171717;
}

.top_blog2 .blog_list > div .blog_text .detail {
  background: #ccc;
  padding: 5px 10px;
  margin-top: 0;
}

@media only screen and (max-width: 640px) {
  .top_blog2 .blog_list > div .blog_text {
    line-height: 1.5em;
  }
  .top_blog2 .blog_list > div .blog_text h3 a {
    font-size: 0.875rem;
  }
}

.items:not(.slick-initialized) {
  display: flex;
  margin-left: 0;
}

.items:not(.slick-initialized) article {
  width: 23% !important;
  margin: 1% !important;
}

.items:not(.slick-initialized) article .photo {
  width: 100% !important;
  height: 170px !important;
}

.items:not(.slick-initialized) article .photo img {
  height: 170px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.items:not(.slick-initialized) article .price {
  text-align: center;
}

.items:not(.slick-initialized) article button {
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .items:not(.slick-initialized) article {
    width: 31.333% !important;
    margin: 1% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .items:not(.slick-initialized) article {
    width: 31.333% !important;
    margin: 1% !important;
  }
}

@media only screen and (max-width: 640px) {
  .items:not(.slick-initialized) article {
    width: 48% !important;
    margin: 1% !important;
  }
}

@media screen and (max-width: 350px) {
  .items:not(.slick-initialized) article {
    width: 100% !important;
    margin: 1% !important;
  }
}

.items article {
  align-items: stretch !important;
}

.custum_shop .items:not(.slick-initialized) {
  display: flex;
  margin-left: 0;
}

.custum_shop .items:not(.slick-initialized) article {
  width: 18% !important;
  margin: 1% !important;
}

.custum_shop .items:not(.slick-initialized) article .photo {
  width: 100% !important;
  height: 170px !important;
}

.custum_shop .items:not(.slick-initialized) article .photo img {
  height: 170px !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.custum_shop .items:not(.slick-initialized) article .price {
  text-align: center;
}

.custum_shop .items:not(.slick-initialized) article button {
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .custum_shop .items:not(.slick-initialized) article {
    width: 23% !important;
    margin: 1% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 835px) {
  .custum_shop .items:not(.slick-initialized) article {
    width: 31.333% !important;
    margin: 1% !important;
  }
}

@media only screen and (max-width: 640px) {
  .custum_shop .items:not(.slick-initialized) article {
    width: 48% !important;
    margin: 1% !important;
  }
}

@media screen and (max-width: 350px) {
  .custum_shop .items:not(.slick-initialized) article {
    width: 100% !important;
    margin: 1% !important;
  }
}

.custum_shop .items article {
  align-items: stretch !important;
}

@media only screen and (max-width: 835px) {
  #col_main .errorbg {
    padding: 0 !important;
  }
}

.slide-bottom {
  opacity: 0;
  transform: translate(0, 50px);
  transition: all 1500ms;
}

.slide-bottom.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top {
  opacity: 0;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-top2 {
  opacity: 0.4;
  transform: translate(0, -50px);
  transition: all 1500ms;
}

.slide-top2.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-right {
  opacity: 0;
  transform: translate(100px, 0px);
  transition: all 2500ms;
}

.slide-right.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left {
  opacity: 0;
  transform: translate(-100px, 0px);
  transition: all 2500ms;
}

.slide-left.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.slide-left2 {
  opacity: 0;
  transform: translate(-100px, 0px);
  transition: all 1000ms;
}

.slide-left2.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}

.works-FLX {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
}

.works-FLX a:hover {
  opacity: 1 !important;
}

.works-FLX .boxL {
  width: 43%;
  margin: 1%;
}

.works-FLX .boxL ul li article div {
  display: none;
}

.works-FLX .boxR, .works-FLX .boxall {
  width: 53%;
  margin: 1%;
}

.works-FLX .boxR ul, .works-FLX .boxall ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.works-FLX .boxR ul li, .works-FLX .boxall ul li {
  width: 32.333%;
  margin: 0 0.5% 1% 0.5%;
}

.works-FLX .boxR ul li article, .works-FLX .boxall ul li article {
  position: relative;
}

.works-FLX .boxR ul li article:before, .works-FLX .boxall ul li article:before {
  content: '';
  display: block;
  padding-top: 100%;
}

.works-FLX .boxR ul li article img, .works-FLX .boxall ul li article img {
  position: absolute;
  top: 0;
  right: 0;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  display: block;
}

.works-FLX .boxR ul li article div, .works-FLX .boxall ul li article div {
  display: none;
}

@media only screen and (max-width: 835px) {
  .works-FLX {
    flex-direction: column;
    align-items: center;
  }
  .works-FLX .boxL {
    width: 90%;
  }
  .works-FLX .boxR, .works-FLX .boxall {
    width: 95%;
  }
}

@media only screen and (max-width: 640px) {
  .works-FLX .boxR ul li, .works-FLX .boxall ul li {
    width: 48%;
  }
}

.works-FLX .boxall {
  width: 100%;
}

.works-FLX .boxall ul li article:before {
  padding-top: 70%;
}

/* title */
.midashi h2 {
  color: #fff;
  line-height: 2em;
  font-size: 40px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .midashi h2 {
    font-size: 20px;
  }
}

.tdpd10 td {
  padding: 10px !important;
}
